import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Third-party libraries
import { v4 as uuidv4 } from 'uuid';

// Material UI
import { Box, Typography, Paper, Button, TextField, Alert } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

// Local components
import WorkflowActions from './WorkflowActions';
import AddActionModal from './AddActionModal';
import OpenAIConfig from './actionConfigs/OpenAIConfig/index';
import WebTechConfig from './actionConfigs/WebTechConfig';
import ScrapeConfig from './actionConfigs/ScrapeConfig';

// Context and utilities
import { useAuth } from '../../context/AuthContext';
import { WorkflowProvider, useWorkflow } from '../../context/WorkflowContext';
import { supabase } from '../../supabaseClient';

// Create a separate component for the editor
const WorkflowEditor = () => {
    const { id: canonicalId } = useParams();
    const { 
        activeWorkflow,
        hasUnsavedChanges,
        saveWorkflow,
        isSaving,
        saveError,
        setUnsavedWorkflow,
        clearUnsavedWorkflow,
        isLoading,
        error,
        fetchLatestWorkflow,
        selectedAction,
        setSelectedAction,
        isAddActionModalOpen,
        setIsAddActionModalOpen
    } = useWorkflow();
    const { activeTenantId, session } = useAuth();
    const [insertPosition, setInsertPosition] = useState(null);
    
    useEffect(() => {
        fetchLatestWorkflow(canonicalId);
    }, [canonicalId]);

    const handleCancelChanges = async () => {
        await clearUnsavedWorkflow(canonicalId);
        window.location.reload(); // The nuclear option
    };

    const handleSave = async () => {
        try {
            await saveWorkflow(canonicalId);
        } catch (err) {
            console.error('Error during save:', err);
        }
    };

    const updateWorkflowAndContext = (updatedWorkflow) => {
        setUnsavedWorkflow(canonicalId, updatedWorkflow);
    };

    const updateActionInWorkflow = (updatedAction) => {
        const newActions = activeWorkflow.actions.map(action => {
            if (action.id === selectedAction.id) {
                return {
                    ...action,
                    ...updatedAction,
                    id: action.id,
                    order: action.order,
                    action_context: {
                        ...action.action_context,
                        ...updatedAction.action_context
                    },
                    data_context: {
                        ...action.data_context,
                        ...updatedAction.data_context,
                        input_data: updatedAction.data_context?.input_data || action.data_context?.input_data,
                        output_data: updatedAction.data_context?.output_data || action.data_context?.output_data,
                        filter: updatedAction.data_context?.filter || action.data_context?.filter
                    }
                };
            }
            return action;
        });
        
        updateWorkflowAndContext({
            ...activeWorkflow,
            actions: newActions
        });
    };

    const getAvailableFieldsForAction = (action, includeCurrentAction = true) => {
        if (!activeWorkflow?.actions) return [];
        
        const fields = [];
        const actionIndex = activeWorkflow.actions.findIndex(a => a.id === action.id);
        
        // Get actions to process
        const actionsToProcess = activeWorkflow.actions
            .filter((a, index) => index < actionIndex || (includeCurrentAction && index === actionIndex));
        
        actionsToProcess.forEach(action => {
            // Add input fields
            if (action.data_context?.input_data) {
                fields.push(...action.data_context.input_data.map(field => ({
                    name: field.name,
                    type: field.type,
                    source: `${action.name} (input)`
                })));
            }
            
            // Add output fields
            if (action.data_context?.output_data) {
                fields.push(...action.data_context.output_data.map(field => ({
                    name: field.name,
                    type: field.type,
                    source: `${action.name} (output)`
                })));
            }
        });

        console.log('action fields for ', action.name, fields);
        console.log('action definition ', action);

        return fields;
    };

    const handleAddAction = (position) => {
        setInsertPosition(position);
        setIsAddActionModalOpen(true);
    };

    const getActionDisplayName = (actionType) => {
        switch (actionType) {
            case 'scrape':
                return 'Web Scraper';
            case 'openai':
                return 'OpenAI';
            case 'get_web_tech':
                return 'Web Technology';
            default:
                return 'Unknown Action';
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography>Loading...</Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">Error: {error}</Typography>
            </Box>
        );
    }

    if (!activeWorkflow) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography>No workflow found</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            {/* Save and Cancel Buttons - only show when there are unsaved changes */}
            {hasUnsavedChanges(canonicalId) && (
                <Box sx={{
                    position: 'fixed',
                    bottom: 24,
                    right: 24,
                    zIndex: 1000,
                    display: 'flex',
                    gap: 2
                }}>
                    <Button
                        variant="outlined"
                        startIcon={<CancelIcon />}
                        onClick={handleCancelChanges}
                        sx={{ bgcolor: 'background.paper' }}
                    >
                        Cancel Changes
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        disabled={isSaving}
                    >
                        {isSaving ? 'Saving...' : 'Save Changes'}
                    </Button>
                </Box>
            )}

            {saveError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    Error saving workflow: {saveError}
                </Alert>
            )}

            <Typography variant="h5" gutterBottom>
                Workflow Configuration: {activeWorkflow.name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Version: {activeWorkflow.version}
            </Typography>

            <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: selectedAction ? '300px 1fr' : '1fr',
                gap: 3,
                mt: 3
            }}>
                <WorkflowActions
                    workflow={activeWorkflow}
                    compact={false}
                    onActionSelect={setSelectedAction}
                    selectedActionId={selectedAction?.id}
                    actions={activeWorkflow.actions || []}
                    setActions={(newActions) => {
                        updateWorkflowAndContext({
                            ...activeWorkflow,
                            actions: newActions
                        });
                    }}
                    onActionsReorder={(reorderedActions) => {
                        updateWorkflowAndContext({
                            ...activeWorkflow,
                            actions: reorderedActions
                        });
                    }}
                    onAddAction={handleAddAction}
                    onDeleteAction={(actionToDelete) => {
                        const newActions = activeWorkflow.actions.filter(a => a !== actionToDelete);
                        updateWorkflowAndContext({
                            ...activeWorkflow,
                            actions: newActions
                        });
                        if (selectedAction === actionToDelete) {
                            setSelectedAction(null);
                        }
                    }}
                />

                {selectedAction && (
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Action Configuration
                        </Typography>
                        
                        {selectedAction.action_type === 'openai' && (
                            <OpenAIConfig 
                                key={`${selectedAction.id}-${activeWorkflow.version}`}
                                action={selectedAction}
                                onChange={updateActionInWorkflow}
                                availableFields={getAvailableFieldsForAction(selectedAction)}
                            />
                        )}
                        
                        {selectedAction.action_type === 'get_web_tech' && (
                            <WebTechConfig 
                                key={`${selectedAction.id}-${activeWorkflow.version}`}
                                action={selectedAction}
                                onChange={updateActionInWorkflow}
                                availableFields={getAvailableFieldsForAction(selectedAction)}
                            />
                        )}
                        
                        {selectedAction.action_type === 'scrape' && (
                            <ScrapeConfig 
                                key={`${selectedAction.id}-${activeWorkflow.version}`}
                                action={selectedAction}
                                onChange={updateActionInWorkflow}
                                availableFields={getAvailableFieldsForAction(selectedAction)}
                            />
                        )}
                    </Paper>
                )}
            </Box>

            <AddActionModal 
                open={isAddActionModalOpen}
                onClose={() => {
                    setIsAddActionModalOpen(false);
                    setInsertPosition(null);
                }}
                onSelectAction={(actionType) => {
                    const currentActions = activeWorkflow.actions || [];
                    
                    // Create new action with proper name and type
                    const newAction = {
                        id: uuidv4(),
                        name: `New ${getActionDisplayName(actionType)} Action`,
                        action_type: actionType,
                        order: insertPosition + 1,
                        data_context: {
                            filter: {
                                operator: 'and',
                                conditions: []
                            },
                            input_data: [],
                            output_data: []
                        },
                        action_context: {},
                        meta_description: ""
                    };

                    // Insert the new action at the correct position
                    const updatedActions = [
                        ...currentActions.slice(0, insertPosition + 1),
                        newAction,
                        ...currentActions.slice(insertPosition + 1)
                    ].map((action, index) => ({
                        ...action,
                        order: index + 1
                    }));

                    // Update workflow with new actions
                    updateWorkflowAndContext({
                        ...activeWorkflow,
                        actions: updatedActions
                    });
                    
                    setIsAddActionModalOpen(false);
                    setInsertPosition(null);
                    setSelectedAction(newAction);
                }}
            />
        </Box>
    );
};

// Main component that wraps the editor with the provider
const WorkflowConfiguration = () => {
    return (
        <WorkflowProvider>
            <WorkflowEditor />
        </WorkflowProvider>
    );
};

export default WorkflowConfiguration;
