import React from 'react';
import { 
    Box, 
    Typography, 
    Paper,
    Grid
} from '@mui/material';

const Cashflow = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Cashflow Report
            </Typography>
            <Paper sx={{ p: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Cashflow report content will be added here.
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default Cashflow; 