import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useWorkflow } from '../../../context/WorkflowContext';
import { 
    Box, 
    Typography, 
    TextField,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper
} from '@mui/material';
import Filter from '../common/Filter';
import ActionNameSection from '../common/ActionNameSection';

const countryOptions = [
    { label: 'Australia', value: 'au' },
    { label: 'Belgium', value: 'be' },
    { label: 'Canada', value: 'ca' },
    { label: 'Chile', value: 'ch' },
    { label: 'China', value: 'cn' },
    { label: 'France', value: 'fr' },
    { label: 'Germany', value: 'de' },
    { label: 'India', value: 'in' },
    { label: 'Italy', value: 'it' },
    { label: 'Mexico', value: 'mx' },
    { label: 'Netherlands', value: 'nl' },
    { label: 'Russia', value: 'ru' },
    { label: 'Singapore', value: 'sg' },
    { label: 'United Kingdom', value: 'gb' },
    { label: 'United States', value: 'us' },
].sort((a, b) => a.label.localeCompare(b.label));



const ScrapeConfig = ({ action, availableFields, onChange }) => {
    const { canonicalId } = useParams();
    const { updateWorkflowAction, unsavedWorkflows, activeWorkflow } = useWorkflow();

    // Always get the latest version of the action from workflow
    const currentWorkflow = unsavedWorkflows[canonicalId] || activeWorkflow;
    const currentAction = currentWorkflow?.actions.find(a => a.id === action.id) || action;

    const [actionName, setActionName] = useState(currentAction.name);
    const [actionContext, setActionContext] = useState(currentAction.action_context || {
        premium: false,
        render_js: false,
        wait_time: 3000,
        geo_targeting: 'us'
    });
    const [inputName, setInputName] = useState(
        currentAction.data_context?.input_data?.[0]?.name || 'website'
    );

    // Sync local state when action changes
    useEffect(() => {
        setActionName(currentAction.name);
        setActionContext(currentAction.action_context || {
            premium: false,
            render_js: false,
            wait_time: 3000,
            geo_targeting: 'us'
        });
        setInputName(currentAction.data_context?.input_data?.[0]?.name || 'website');
    }, [currentAction]);

    const handleUpdate = (updates) => {
        const updatedAction = {
            ...currentAction,
            ...updates,
            name: updates.name || actionName,
            action_context: {
                ...currentAction.action_context,
                ...updates.action_context
            },
            data_context: {
                ...currentAction.data_context,
                ...updates.data_context,
                input_data: updates.data_context?.input_data || [{
                    name: inputName,
                    type: 'text'
                }],
                output_data: [
                    {
                        name: `${actionName} HTML`,
                        type: 'html'
                    },
                    {
                        name: `${actionName} Markdown`,
                        type: 'markdown'
                    }
                ]
            }
        };
        updateWorkflowAction(canonicalId, currentAction.id, updatedAction);
        onChange?.(updatedAction);
    };

    const handleNameChange = (newName) => {
        setActionName(newName);
        handleUpdate({ name: newName });
    };

    const handleInputNameChange = (e) => {
        const newInputName = e.target.value;
        setInputName(newInputName);
        handleUpdate({
            data_context: {
                input_data: [{
                    name: newInputName,
                    type: 'text'
                }]
            }
        });
    };

    const handleContextChange = (updates) => {
        const newContext = { ...actionContext, ...updates };
        setActionContext(newContext);
        handleUpdate({ action_context: newContext });
    };

    const handleFilterChange = (newFilter) => {
        handleUpdate({
            data_context: {
                ...currentAction.data_context,
                filter: newFilter
            }
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ActionNameSection 
                name={actionName}
                onChange={handleNameChange}
            />

            {/* Scraping Options Section */}
            <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Scraping Options
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={actionContext.render_js}
                                onChange={(e) => handleContextChange({ 
                                    render_js: e.target.checked,
                                    wait_time: e.target.checked ? actionContext.wait_time : null
                                })}
                            />
                        }
                        label="Render JavaScript"
                    />

                    <TextField
                        label="Wait Time (ms)"
                        type="number"
                        value={actionContext.wait_time || ''}
                        onChange={(e) => handleContextChange({ 
                            wait_time: parseInt(e.target.value, 10)
                        })}
                        disabled={!actionContext.render_js}
                        InputProps={{ 
                            inputProps: { 
                                min: 0,
                                max: 30000
                            }
                        }}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={actionContext.premium}
                                onChange={(e) => handleContextChange({ 
                                    premium: e.target.checked,
                                    geo_targeting: e.target.checked ? actionContext.geo_targeting : null
                                })}
                            />
                        }
                        label="Premium Features"
                    />

                    <FormControl fullWidth disabled={!actionContext.premium}>
                        <InputLabel>Geo Targeting</InputLabel>
                        <Select
                            value={actionContext.geo_targeting || ''}
                            onChange={(e) => handleContextChange({ 
                                geo_targeting: e.target.value
                            })}
                            label="Geo Targeting"
                        >
                            {countryOptions.map((country) => (
                                <MenuItem key={country.value} value={country.value}>
                                    {country.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Paper>

            {/* Data Configuration Section */}
            <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Data Configuration
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Website Input Field Name"
                        value={inputName}
                        onChange={handleInputNameChange}
                        fullWidth
                    />

                    <Typography variant="subtitle2" sx={{ mt: 1 }}>Output Data</Typography>
                    <Box sx={{ pl: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                            • {actionName} HTML (type: html)
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            • {actionName} Markdown (type: markdown)
                        </Typography>
                    </Box>
                </Box>
            </Paper>

            {/* Filter Configuration Section */}
            <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Filter Configuration
                </Typography>
                <Filter
                    filter={currentAction.data_context?.filter}
                    onFilterChange={handleFilterChange}
                    availableFields={availableFields}
                />
            </Paper>
        </Box>
    );
};

export default ScrapeConfig; 