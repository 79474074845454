import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Paper, FormControl, Select, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useWorkflow } from '../../../context/WorkflowContext';
import Filter from '../common/Filter';
import ActionNameSection from '../common/ActionNameSection';

const AVAILABLE_TECH = {
    'hubspot': {
        label: 'HubSpot',
        context: {
            data: [
                {
                    tag: "script",
                    keywords: [
                        "hs-forms.js",
                        "js.hsforms.net",
                        "hsleadflows.net",
                        "hs-analytics.net",
                        "hscollectedforms.net",
                        "js.hs-scripts.com",
                        "/hs/hsstatic/",
                        "/hs/cta/cta/current.js",
                        "hsappstatic.net",
                        "hubspot.com/"
                    ],
                    attributes: ["src"]
                },
                {
                    tag: "script",
                    keywords: ["hs-script-loader"],
                    attributes: ["id"]
                }
            ]
        }
    },
    // Add more technologies as needed
};

const WebTechConfig = ({ action, onChange, availableFields = [] }) => {
    const { canonicalId } = useParams();
    const { updateWorkflowAction, unsavedWorkflows, activeWorkflow } = useWorkflow();

    // Always get the latest version of the action from workflow
    const currentWorkflow = unsavedWorkflows[canonicalId] || activeWorkflow;
    const currentAction = currentWorkflow?.actions.find(a => a.id === action.id) || action;

    const [actionName, setActionName] = useState(currentAction.name);
    const [selectedTech, setSelectedTech] = useState(currentAction.action_context?.tech || 'hubspot');
    const [inputName, setInputName] = useState(currentAction.data_context?.input_data?.[0]?.name || '');
    const [outputName, setOutputName] = useState(
        currentAction.data_context?.output_data?.[0]?.name || 
        `Has ${AVAILABLE_TECH[currentAction.action_context?.tech || 'hubspot']?.label}`
    );

    // Sync local state when action changes
    useEffect(() => {
        setActionName(currentAction.name);
        setSelectedTech(currentAction.action_context?.tech || 'hubspot');
        setInputName(currentAction.data_context?.input_data?.[0]?.name || '');
        setOutputName(
            currentAction.data_context?.output_data?.[0]?.name || 
            `Has ${AVAILABLE_TECH[currentAction.action_context?.tech || 'hubspot']?.label}`
        );
    }, [currentAction]);

    const handleUpdate = (updates) => {
        const updatedAction = {
            ...currentAction,
            ...updates,
            name: updates.name || actionName,
            action_context: {
                ...currentAction.action_context,
                tech: updates.tech || selectedTech,
                context: AVAILABLE_TECH[updates.tech || selectedTech].context
            },
            data_context: {
                ...currentAction.data_context,
                input_data: [{
                    name: updates.inputName || inputName,
                    type: "html"
                }],
                output_data: [{
                    name: updates.outputName || outputName,
                    type: "bool"
                }],
                filter: updates.filter || currentAction.data_context?.filter
            }
        };
        updateWorkflowAction(canonicalId, currentAction.id, updatedAction);
        onChange?.(updatedAction);
    };

    const handleNameChange = (newName) => {
        setActionName(newName);
        handleUpdate({ name: newName });
    };

    const handleInputNameChange = (e) => {
        const newInputName = e.target.value;
        setInputName(newInputName);
        handleUpdate({ inputName: newInputName });
    };

    const handleOutputNameChange = (e) => {
        const newOutputName = e.target.value;
        setOutputName(newOutputName);
        handleUpdate({ outputName: newOutputName });
    };

    const handleFilterChange = (newFilter) => {
        handleUpdate({
            data_context: {
                ...currentAction.data_context,
                filter: newFilter
            }
        });
    };

    // Get available fields including the output field
    const allAvailableFields = [
        ...availableFields,
        { name: outputName, type: 'bool' }
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ActionNameSection 
                name={actionName}
                onChange={handleNameChange}
            />

            {/* Technology Selection Section */}
            <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Technology Detection
                </Typography>
                <FormControl fullWidth>
                    <Select
                        value={selectedTech}
                        onChange={(e) => {
                            setSelectedTech(e.target.value);
                            handleUpdate({ tech: e.target.value });
                        }}
                    >
                        {Object.entries(AVAILABLE_TECH).map(([key, tech]) => (
                            <MenuItem key={key} value={key}>
                                {tech.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Paper>

            {/* Data Configuration Section */}
            <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Data Configuration
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControl fullWidth>
                        <Typography variant="subtitle2" gutterBottom>
                            HTML Input Field
                        </Typography>
                        <Select
                            value={inputName}
                            onChange={handleInputNameChange}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                <em>Select input field</em>
                            </MenuItem>
                            {availableFields
                                .filter(field => field.type === 'html')
                                .map((field) => (
                                    <MenuItem key={field.name} value={field.name}>
                                        {field.name} ({field.type})
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <Typography variant="subtitle2" sx={{ mt: 1 }}>Output Data</Typography>
                    <Box sx={{ pl: 2 }}>
                        <TextField
                            fullWidth
                            label="Output Field Name"
                            value={outputName}
                            onChange={handleOutputNameChange}
                            helperText="Type: bool"
                        />
                    </Box>
                </Box>
            </Paper>

            {/* Filter Configuration Section */}
            <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Filter Configuration
                </Typography>
                <Filter
                    filter={currentAction.data_context?.filter}
                    onFilterChange={handleFilterChange}
                    availableFields={allAvailableFields}
                />
            </Paper>
        </Box>
    );
};

export default WebTechConfig; 