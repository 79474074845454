import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useWorkflow } from '../../../../context/WorkflowContext';
import ActionNameSection from '../../common/ActionNameSection';
import Filter from '../../common/Filter';
import ChatConfigSection from './ChatConfigSection';
import ParameterPanel from './ParameterPanel';

const OpenAIConfig = ({ action, onChange, availableFields: initialAvailableFields }) => {
    const { canonicalId } = useParams();
    const { updateWorkflowAction, unsavedWorkflows, activeWorkflow } = useWorkflow();

    // Always get the latest version of the action from workflow
    const currentWorkflow = unsavedWorkflows[canonicalId] || activeWorkflow;
    const currentAction = currentWorkflow?.actions.find(a => a.id === action.id) || action;

    const [actionName, setActionName] = useState(currentAction.name);
    const [parameters, setParameters] = useState(currentAction.action_context?.parameters || {});
    const [chatConfig, setChatConfig] = useState({
        user_content: currentAction.action_context?.user_content || '',
        function_name: currentAction.action_context?.function_name || '',
        system_content: currentAction.action_context?.system_content || '',
        function_description: currentAction.action_context?.function_description || '',
        model: currentAction.action_context?.model || 'gpt-4o-mini'
    });

    // Sync local state when action changes
    useEffect(() => {
        setActionName(currentAction.name);
        setParameters(currentAction.action_context?.parameters || {});
        setChatConfig({
            user_content: currentAction.action_context?.user_content || '',
            function_name: currentAction.action_context?.function_name || '',
            system_content: currentAction.action_context?.system_content || '',
            function_description: currentAction.action_context?.function_description || '',
            model: currentAction.action_context?.model || 'gpt-4o-mini'
        });
    }, [currentAction]);

    const handleUpdate = (updates) => {
        const updatedAction = {
            ...currentAction,
            ...updates,
            name: updates.name || actionName,
            action_context: {
                ...currentAction.action_context,
                ...updates.action_context,
                parameters: updates.action_context?.parameters || parameters
            },
            data_context: {
                ...currentAction.data_context,
                ...updates.data_context
            }
        };
        updateWorkflowAction(canonicalId, currentAction.id, updatedAction);
        onChange?.(updatedAction);
    };

    const handleNameChange = (newName) => {
        setActionName(newName);
        handleUpdate({ name: newName });
    };

    // Convert parameter to output_data format
    const parameterToOutputData = (paramName, paramData) => ({
        name: paramName,
        type: paramData.type,
        ...(paramData.enum && {
            enum: paramData.enum.map(item => item.value)
        })
    });

    // Update both parameters and output_data
    const handleParameterUpdate = (paramName, paramData, newParamName) => {
        const newParameters = { ...parameters };
        let newOutputData = [...(currentAction.data_context?.output_data || [])];
        
        if (newParamName) {
            // Handle rename
            delete newParameters[paramName];
            newParameters[newParamName] = paramData;
            
            // Update output_data
            newOutputData = newOutputData.filter(output => output.name !== paramName);
            newOutputData.push(parameterToOutputData(newParamName, paramData));
        } else {
            // Handle update
            newParameters[paramName] = paramData;
            
            // Update output_data
            const outputIndex = newOutputData.findIndex(output => output.name === paramName);
            if (outputIndex !== -1) {
                newOutputData[outputIndex] = parameterToOutputData(paramName, paramData);
            } else {
                newOutputData.push(parameterToOutputData(paramName, paramData));
            }
        }
        
        setParameters(newParameters);
        handleUpdate({
            action_context: {
                parameters: newParameters
            },
            data_context: {
                ...currentAction.data_context,
                output_data: newOutputData
            }
        });

    };

    const handleParameterDelete = (paramName) => {
        const newParameters = { ...parameters };
        delete newParameters[paramName];
        
        // Remove from output_data
        const newOutputData = (currentAction.data_context?.output_data || [])
            .filter(output => output.name !== paramName);
        
        setParameters(newParameters);
        handleUpdate({
            action_context: {
                parameters: newParameters
            },
            data_context: {
                ...currentAction.data_context,
                output_data: newOutputData
            }
        });
    };

    const handleParameterAdd = (paramName) => {
        const newParameter = { type: 'string', description: '' };
        const newParameters = {
            ...parameters,
            [paramName]: newParameter
        };
        
        // Add to output_data
        const newOutputData = [
            ...(currentAction.data_context?.output_data || []),
            parameterToOutputData(paramName, newParameter)
        ];
        
        setParameters(newParameters);
        handleUpdate({
            action_context: {
                parameters: newParameters
            },
            data_context: {
                ...currentAction.data_context,
                output_data: newOutputData
            }
        });
    };

    const handleFilterChange = (newFilter) => {
        handleUpdate({
            data_context: {
                ...currentAction.data_context,
                filter: newFilter
            }
        });
    };

    const handleChatConfigChange = (updates) => {
        const newChatConfig = { ...chatConfig, ...updates };
        setChatConfig(newChatConfig);
        handleUpdate({
            action_context: {
                ...currentAction.action_context,
                ...newChatConfig
            }
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ActionNameSection 
                name={actionName}
                onChange={handleNameChange}
            />
            
            <ChatConfigSection 
                chatConfig={chatConfig}
                onChange={handleChatConfigChange}
                availableFields={initialAvailableFields}
            />
            
            <ParameterPanel 
                parameters={parameters}
                onParameterUpdate={handleParameterUpdate}
                onParameterDelete={handleParameterDelete}
                onParameterAdd={handleParameterAdd}
                availableFields={initialAvailableFields}
            />
            
            <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Filter Configuration
                </Typography>
                <Filter
                    filter={currentAction.data_context?.filter}
                    onFilterChange={handleFilterChange}
                    availableFields={initialAvailableFields}
                />
            </Paper>
        </Box>
    );
};

export default OpenAIConfig; 