import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Paper, 
    Grid, 
    Card, 
    CardContent, 
    CardActionArea,
    Button,
    Skeleton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../supabaseClient';

const Configure = () => {
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const { activeTenantId } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        fetchBusinesses();
    }, [activeTenantId]);

    const fetchBusinesses = async () => {
        try {
            const { data, error } = await supabase
                .from('businesses')
                .select('*')
                .eq('tenant_id', activeTenantId);

            if (error) throw error;
            setBusinesses(data || []);
        } catch (error) {
            console.error('Error fetching businesses:', error);
            // You might want to add error handling UI here
        } finally {
            setLoading(false);
        }
    };

    const handleBusinessClick = (businessId) => {
        navigate(`/business/configure/business/${businessId}`);
    };

    const handleAddBusiness = () => {
        navigate('/business/configure/business/new');
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography variant="h5" component="h1">
                    Businesses
                </Typography>
                <Button 
                    variant="contained" 
                    onClick={handleAddBusiness}
                    startIcon={<AddIcon />}
                >
                    Add Business
                </Button>
            </Box>

            <Grid container spacing={3}>
                {loading ? (
                    // Loading skeletons
                    [...Array(3)].map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
                            <Skeleton 
                                variant="rectangular" 
                                height={140} 
                                sx={{ borderRadius: 1 }}
                            />
                        </Grid>
                    ))
                ) : businesses.length === 0 ? (
                    // Empty state
                    <Grid item xs={12}>
                        <Paper 
                            sx={{ 
                                p: 3, 
                                textAlign: 'center',
                                backgroundColor: 'grey.100'
                            }}
                        >
                            <Typography variant="body1" color="textSecondary">
                                No businesses found. Click the button above to add your first business.
                            </Typography>
                        </Paper>
                    </Grid>
                ) : (
                    // Business cards
                    businesses.map((business) => (
                        <Grid item xs={12} sm={6} md={4} key={business.id}>
                            <Card>
                                <CardActionArea 
                                    onClick={() => handleBusinessClick(business.id)}
                                    sx={{ height: '100%' }}
                                >
                                    <CardContent>
                                        <Typography variant="h6" component="h2" gutterBottom>
                                            {business.name}
                                        </Typography>
                                        {business.description && (
                                            <Typography variant="body2" color="textSecondary">
                                                {business.description}
                                            </Typography>
                                        )}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))
                )}
            </Grid>
        </Box>
    );
};

export default Configure;