const routes = {
    '/': {
        items: [{ text: 'Home' }]
    },
    '/dashboards': {
        items: [{ text: 'Dashboards' }]
    },
    '/business': {
        items: [{ text: 'Business Model' }]
    },
    '/form-shortening': {
        items: [{ text: 'Form Shortening' }]
    },
    '/custom-workflows': {
        items: [{ text: 'Custom Workflows' }]
    },
    '/workspace': {
        items: [
            { text: 'Workspace Settings' }
        ]
    },
    '/user/settings': {
        items: [
            { text: 'User Settings' }
        ]
    },
    '/user/reset-password': {
        items: [
            { text: 'Reset Password' }
        ]
    },
    '/business/configure': {
        items: [
            { text: 'Business Model', href: '/business' },
            { text: 'Configuration' }
        ]
    },
    '/business/configure/business/new': {
        items: [
            { text: 'Business Model', href: '/business' },
            { text: 'Configuration', href: '/business/configure' },
            { text: 'New Business' }
        ]
    },
    '/business/campaigns': {
        items: [
            { text: 'Business Model', href: '/business' },
            { text: 'Campaigns' }
        ]
    },
    '/reports': {
        items: [
            { text: 'Reports' }
        ]
    }
    ,
    '/reports/lifecycle-cohort': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Lifecycle Cohort' }

        ]
    },
    '/reports/conversions': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Conversions' }

        ]
    },
    '/reports/funnels': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Funnels' }

        ]
    },
    '/reports/funnels/new': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Funnels', href: '/reports/funnels' },
            { text: 'New Funnel' }

        ]
    },
    '/enrichment': {
        items: [{ text: 'Data Enrichment' }]
    },
    '/enrichment/workflows/:id/run': {
        items: [
            { text: 'Data Enrichment', href: '/enrichment' },
            { text: 'Workflow Run' }
        ]
    },
    '/enrichment/workflows/:id/batch/:batchId': {
        items: [
            { text: 'Data Enrichment', href: '/enrichment' },
            { text: 'Workflow Run', href: '/enrichment/workflows/:id/run' },
            { text: 'Batch Details' }
        ]
    },
    '/enrichment/workflows/:id/configure': {
        items: [
            { text: 'Data Enrichment', href: '/enrichment' },
            { text: 'Workflow Configuration' }
        ]
    },
    '/my-custom-workflows': {
        items: [{ text: 'My Custom Workflows' }]
    },
    '/reports/cashflow': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Cashflow' }
        ]
    }
};

// Helper function to handle dynamic routes
export const getBreadcrumbItems = (pathname) => {
    // First check for exact matches
    if (routes[pathname]) {
        return routes[pathname].items;
    }

    // Handle dynamic routes
    if (pathname.match(/\/custom-workflows\/[^/]+/)) {
        return [
            { text: 'Custom Workflows', href: '/custom-workflows' },
            { text: 'Workflow Details' }
        ];
    }

    if (pathname.match(/\/enrichment\/workflows\/[^/]+\/run/)) {
        return [
            { text: 'Data Enrichment', href: '/enrichment' },
            { text: 'Workflow Run' }
        ];
    }

    if (pathname.match(/\/funnels\/.*\/edit/)) {
        return [
            { text: 'Reports', href: '/reports' },
            { text: 'Funnels', href: '/reports/funnels' },
            { text: 'Edit Funnel' }
        ];
    }

    if (pathname.match(/\/funnels\/new/)) {
        return [
            { text: 'Reports', href: '/reports' },
            { text: 'Reports', href: '/reports/funnels' },
            { text: 'New Funnel' }
        ];
    }

    if (pathname.match(/\/enrichment\/workflows\/[^/]+\/batch\/[^/]+/)) {
        const parts = pathname.split('/');
        const workflowId = parts[3];
        const batchId = parts[5];
        return [
            { text: 'Data Enrichment', href: '/enrichment' },
            { text: 'Workflow Run', href: `/enrichment/workflows/${workflowId}/run` },
            { text: 'Batch Details' }
        ];
    }

    if (pathname.match(/\/enrichment\/workflows\/[^/]+\/configure/)) {
        return [
            { text: 'Data Enrichment', href: '/enrichment' },
            { text: 'Workflow Configuration' }
        ];
    }

    if (pathname.match(/\/my-custom-workflows\/[^/]+\/[^/]+/)) {
        const parts = pathname.split('/');
        return [
            { text: 'My Custom Workflows', href: '/my-custom-workflows' },
            { text: 'Workflow Details', href: `/my-custom-workflows/${parts[2]}` },
            { text: 'Run Details' }
        ];
    }

    // Default fallback
    return [{ text: 'Home' }];
};

export default routes; 