import React, { useState, useRef } from 'react';
import { Paper, Typography, Box, MenuItem, Select, FormControl, InputLabel, TextField, Popover, List, ListItem, ListItemText } from '@mui/material';


const HighlightedTextField = ({ value, onChange, availableFields = [], ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const textFieldRef = useRef(null);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        onChange({ target: { value: newValue } });

        const cursorPosition = e.target.selectionStart;
        const lastHashIndex = newValue.lastIndexOf('#', cursorPosition - 1);

        if (lastHashIndex !== -1 && cursorPosition > lastHashIndex) {
            const query = newValue.slice(lastHashIndex + 1, cursorPosition);
            const filteredFields = availableFields.filter((field) => {
                const fieldName = typeof field === 'object' ? field.name : field;
                return fieldName.toLowerCase().startsWith(query.toLowerCase());
            });

            if (filteredFields.length > 0) {
                const textField = textFieldRef.current;
                const rect = textField.getBoundingClientRect();
                const caretCoords = getCaretCoordinates(textField, cursorPosition);
                setAnchorEl({
                    top: rect.top + caretCoords.top,
                    left: rect.left + caretCoords.left,
                });
                setSelectedIndex(0);
            } else {
                setAnchorEl(null);
            }
        } else {
            setAnchorEl(null);
        }
    };

    // Helper function to get caret coordinates
    const getCaretCoordinates = (element, position) => {
        const div = document.createElement('div');
        const copyStyle = getComputedStyle(element);
        for (const prop of copyStyle) {
            div.style[prop] = copyStyle[prop];
        }
        div.style.position = 'absolute';
        div.style.visibility = 'hidden';
        div.style.whiteSpace = 'pre-wrap';
        div.textContent = element.value.substring(0, position);
        const span = document.createElement('span');
        span.textContent = element.value.substring(position) || '.';
        div.appendChild(span);
        document.body.appendChild(div);
        const { offsetTop: top, offsetLeft: left } = span;
        document.body.removeChild(div);
        return { top, left };
    };

    const handleFieldSelect = (field) => {
        const fieldName = typeof field === 'object' ? field.name : field;
        const cursorPosition = textFieldRef.current.selectionStart;
        const lastHashIndex = value.lastIndexOf('#', cursorPosition - 1);

        const beforeHash = value.slice(0, lastHashIndex);
        const afterCursor = value.slice(cursorPosition);

        const updatedValue = `${beforeHash}{{{${fieldName}}}}${afterCursor}`;
        onChange({ target: { value: updatedValue } });
        setAnchorEl(null);
    };

    const handleKeyDown = (e) => {
        if (anchorEl) {
            const filteredFields = getFilteredFields();

            if (e.key === 'ArrowDown') {
                setSelectedIndex((prev) => {
                    const newIndex = (prev + 1) % filteredFields.length;
                    return newIndex;
                });
                e.preventDefault();
            } else if (e.key === 'ArrowUp') {
                setSelectedIndex((prev) => {
                    const newIndex = (prev - 1 + filteredFields.length) % filteredFields.length;
                    return newIndex;
                });
                e.preventDefault();
            } else if (e.key === 'Enter') {
                if (filteredFields.length > 0) {
                    handleFieldSelect(filteredFields[selectedIndex]);
                }
                e.preventDefault();
            } else if (e.key === 'Escape') {
                setAnchorEl(null);
                e.preventDefault();
            }
        }
    };

    // Ensure the text field retains focus
    const handleFocus = () => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    };

    // Get filtered fields for the popover
    const getFilteredFields = () => {
        if (!textFieldRef.current) return [];
        const cursorPosition = textFieldRef.current.selectionStart;
        const lastHashIndex = value.lastIndexOf('#', cursorPosition - 1);
        const query = value.slice(lastHashIndex + 1, cursorPosition);

        return availableFields.filter((field) => {
            const fieldName = typeof field === 'object' ? field.name : field;
            return fieldName.toLowerCase().startsWith(query.toLowerCase());
        });
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <TextField
                {...props}
                inputRef={textFieldRef}
                value={value}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                multiline
                rows={props.rows || 4}
                fullWidth
            />
            <Popover
                open={Boolean(anchorEl)}
                anchorReference="anchorPosition"
                anchorPosition={anchorEl}
                onClose={() => setAnchorEl(null)}
                disableAutoFocus
                disableEnforceFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List sx={{ maxHeight: '200px', overflow: 'auto' }}>
                    {getFilteredFields().map((field, index) => (
                        <ListItem
                            button
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => handleFieldSelect(field)}
                            sx={{
                                minWidth: '150px',
                                maxWidth: '250px',
                                py: 0.5,
                                px: 1.5,
                                bgcolor: index === selectedIndex ? 'action.hover' : 'inherit',
                            }}
                        >
                            <ListItemText 
                                primary={typeof field === 'object' ? field.name : field}
                                primaryTypographyProps={{
                                    sx: { fontSize: '0.875rem' }
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </Box>
    );
};

const ChatConfigSection = ({ chatConfig, onChange, availableFields }) => {
    const handleChange = (field) => (event) => {
        onChange({ [field]: event.target.value });
    };

    const MODEL_OPTIONS = [
        { value: 'gpt-4o-mini', label: 'GPT-4o Mini' },
        { value: 'gpt-4o', label: 'GPT-4O' },
        { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
    ];


    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
                Chat Configuration
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl fullWidth size="small">
                    <InputLabel>Model</InputLabel>
                    <Select
                        value={chatConfig.model || 'gpt-4o-mini'}
                        label="Model"
                        onChange={handleChange('model')}
                    >
                        {MODEL_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    label="Function Name"
                    value={chatConfig.function_name}
                    onChange={handleChange('function_name')}
                    size="small"
                />
                <HighlightedTextField
                    fullWidth
                    label="Function Description"
                    value={chatConfig.function_description}
                    onChange={(e) => handleChange('function_description')(e)}
                    availableFields={availableFields}
                    size="small"
                />
                <HighlightedTextField
                    fullWidth
                    multiline
                    rows={4}
                    label="System Content"
                    value={chatConfig.system_content}
                    onChange={(e) => handleChange('system_content')(e)}
                    availableFields={availableFields}
                />
                <HighlightedTextField
                    fullWidth
                    multiline
                    rows={4}
                    label="User Content"
                    value={chatConfig.user_content}
                    onChange={(e) => handleChange('user_content')(e)}
                    availableFields={availableFields}
                    helperText="Type # to reference available fields"
                />
            </Box>
        </Paper>
    );
};

export default ChatConfigSection; 