import React, { createContext, useContext, useState } from 'react';
import { useAuth } from './AuthContext';
import { supabase } from '../supabaseClient';

const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;


const WorkflowContext = createContext();

export const WorkflowProvider = ({ children }) => {
    const { activeTenantId, session } = useAuth();
    const [unsavedWorkflows, setUnsavedWorkflows] = useState({});
    const [activeWorkflow, setActiveWorkflow] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [isAddActionModalOpen, setIsAddActionModalOpen] = useState(false);

    const setUnsavedWorkflow = (canonicalId, workflow) => {
        setUnsavedWorkflows(prev => ({
            ...prev,
            [canonicalId]: workflow
        }));
        if (activeWorkflow?.canonical_id === canonicalId) {
            setActiveWorkflow(workflow);
        }
    };

    const updateWorkflowAction = (canonicalId, actionId, updatedAction) => {
        const workflow = unsavedWorkflows[canonicalId] || activeWorkflow;
        if (!workflow) return;

        const updatedWorkflow = {
            ...workflow,
            actions: workflow.actions.map(action =>
                action.id === actionId ? updatedAction : action
            )
        };

        setUnsavedWorkflow(canonicalId, updatedWorkflow);
    };

    const saveWorkflow = async (canonicalId) => {
        try {
            setIsSaving(true);
            setSaveError(null);
            
            const workflowToSave = unsavedWorkflows[canonicalId];
            if (!workflowToSave) return;

            // Create new version
            const newVersion = (workflowToSave.version || 0) + 1;
            
            // Prepare the request payload according to SaveVersionRequest
            const saveRequest = {
                tenant_id: activeTenantId,
                canonical_id: canonicalId,
                actions: workflowToSave.actions,
                version: newVersion
            };

            const response = await fetch(`${API_URL}/api/pipelines/save_version`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Assuming you store the auth token in localStorage or similar
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify(saveRequest)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to save workflow');
            }

            const data = await response.json();

            // Update active workflow with saved version
            setActiveWorkflow(data);
            clearUnsavedWorkflow(canonicalId);
            
            return data;
        } catch (error) {
            console.error('Error saving workflow:', error);
            setSaveError(error.message);
            throw error;
        } finally {
            setIsSaving(false);
        }
    };

    const getUnsavedWorkflow = (canonicalId) => {
        return unsavedWorkflows[canonicalId];
    };

    const fetchLatestWorkflow = async (canonicalId) => {
        try {
            setIsLoading(true);
            setError(null);

            const { data, error } = await supabase
                .from('icp_segment_enrichment_workflows')
                .select(`
                    *,
                    icp_segments (
                        icp_name
                    )
                `)
                .eq('canonical_id', canonicalId)
                .eq('tenant_id', activeTenantId)
                .order('version', { ascending: false })
                .limit(1)
                .single();

            if (error) throw error;
            setActiveWorkflow(data);
        } catch (err) {
            console.error('Error fetching workflow:', err);
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const clearUnsavedWorkflow = async (canonicalId) => {
        setUnsavedWorkflows(prev => {
            const newState = { ...prev };
            delete newState[canonicalId];
            return newState;
        });
        
        // Fetch latest workflow after clearing unsaved changes
        await fetchLatestWorkflow(canonicalId);
    };

    const hasUnsavedChanges = (canonicalId) => {
        return !!unsavedWorkflows[canonicalId];
    };

    return (
        <WorkflowContext.Provider value={{
            activeWorkflow,
            setActiveWorkflow,
            unsavedWorkflows,
            setUnsavedWorkflow,
            getUnsavedWorkflow,
            clearUnsavedWorkflow,
            hasUnsavedChanges,
            updateWorkflowAction,
            saveWorkflow,
            isSaving,
            saveError,
            isLoading,
            error,
            fetchLatestWorkflow,
            selectedAction,
            setSelectedAction,
            isAddActionModalOpen,
            setIsAddActionModalOpen,
        }}>
            {children}
        </WorkflowContext.Provider>
    );
};

export const useWorkflow = () => {
    const context = useContext(WorkflowContext);
    if (!context) {
        throw new Error('useWorkflow must be used within a WorkflowProvider');
    }
    return context;
}; 