import { useState } from 'react';
import { supabase } from '../../supabaseClient';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CustomSignup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [termsError, setTermsError] = useState('');
    const [isSignupComplete, setIsSignupComplete] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setTermsError('');
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setEmail(email);
        if (email && !validateEmail(email)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!isChecked) {
            setTermsError('You must accept the terms and conditions to sign up.');
            return;
        }
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }
        const { data, error } = await supabase.auth.signUp({
            email,
            password,
        });
        
        if (error) {
            console.error("Error signing up:", error.message);
            alert(error.message);
        } else if (data?.user?.identities?.length === 0) {
            alert("This email is already registered. Please log in instead.");
        } else {
            setIsSignupComplete(true);
        }
    };

    const handleGoogleSignup = async () => {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                redirectTo: window.env?.REACT_APP_FRONTEND_URL || process.env.REACT_APP_FRONTEND_URL,
            },
        });
        if (error) {
            console.error("Error with Google signup:", error.message);
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 4 }}>
                {!isSignupComplete ? (
                    <>
                        <button className="gsi-material-button" style={{ width: '400px' }} onClick={handleGoogleSignup}>
                            <div className="gsi-material-button-state"></div>
                            <div className="gsi-material-button-content-wrapper">
                                <div className="gsi-material-button-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: 'block' }}>
                                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                        <path fill="none" d="M0 0h48v48H0z"></path>
                                    </svg>
                                </div>
                                <span className="gsi-material-button-contents">Sign up with Google</span>
                            </div>
                        </button>
                        <Divider sx={{ width: '100%', marginBottom: 2, marginTop: 2 }}>or</Divider>
                        <Box component="form" onSubmit={handleSignup} sx={{ width: '100%', maxWidth: '300px' }}>
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                fullWidth
                                required
                                margin="normal"
                                error={!!emailError}
                                helperText={emailError}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        color="primary"
                                        error={!!termsError}
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        I accept the <Link href="https://collextr.com/terms" target="_blank" rel="noopener noreferrer">Terms & Conditions</Link>
                                    </Typography>
                                }
                                sx={{ marginY: 2 }}
                            />
                            {termsError && (
                                <Typography 
                                    variant="caption" 
                                    color="error" 
                                    sx={{ display: 'block', mt: -1, mb: 2 }}
                                >
                                    {termsError}
                                </Typography>
                            )}
                            <Typography variant="body2" sx={{ marginBottom: 2 }}>
                                Already have an account? <Link href="/login">Log in here</Link>
                            </Typography>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                Sign Up
                            </Button>
                        </Box>
                    </>
                ) : (
                    <Box sx={{ 
                        textAlign: 'center',
                        maxWidth: '400px',
                        p: 3,
                        border: 1,
                        borderColor: 'primary.main',
                        borderRadius: 1,
                        bgcolor: 'background.paper'
                    }}>
                        <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                            Email Confirmation Required
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            We've sent an email to <strong>{email}</strong>. 
                            Please confirm your signup by following instructions in the email.
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Don't see the email? Check your spam folder.
                        </Typography>
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Link href="https://collextr.com/privacy-policy" sx={{ marginRight: 1 }}>Privacy Policy</Link>
                    <Link href="https://collextr.com/terms" sx={{ marginLeft: 1 }}>Terms & Conditions</Link>
                </Box>
            </Box>
            <Box sx={{ flex: 1, backgroundImage: 'url(/images/signin_cover.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }} />
        </Box>
    );
};

export default CustomSignup;
