import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Paper } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes

function DropzoneArea({ uploadFile, uploadError, onFileChange, onErrorChange }) {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    onErrorChange(null);

    if (!file) return;

    // Validate file type
    if (file.type !== 'text/csv') {
      onErrorChange('Only CSV files are allowed');
      return;
    }

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      onErrorChange('File size must be less than 1MB');
      return;
    }

    onFileChange(file);
  }, [onFileChange, onErrorChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv']
    },
    maxSize: MAX_FILE_SIZE,
    multiple: false
  });

  return (
    <Paper
      {...getRootProps()}
      sx={{
        border: '2px dashed',
        borderColor: theme => 
          uploadError ? theme.palette.error.main :
          isDragActive ? theme.palette.primary.main : 
          theme.palette.divider,
        borderRadius: 1,
        bgcolor: theme => 
          isDragActive ? `${theme.palette.primary.main}10` : 
          'background.paper',
        p: 3,
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        '&:hover': {
          borderColor: 'primary.main',
          bgcolor: theme => `${theme.palette.primary.main}10`
        }
      }}
    >
      <input {...getInputProps()} />
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
      >
        {uploadFile ? (
          <>
            <InsertDriveFileIcon color="primary" sx={{ fontSize: 48 }} />
            <Typography variant="body1" color="primary">
              {uploadFile.name}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Click or drag to replace
            </Typography>
          </>
        ) : (
          <>
            <CloudUploadIcon 
              sx={{ 
                fontSize: 48,
                color: isDragActive ? 'primary.main' : 'text.secondary'
              }} 
            />
            <Typography
              variant="body1"
              color={isDragActive ? 'primary.main' : 'text.secondary'}
              textAlign="center"
            >
              {isDragActive
                ? 'Drop the CSV file here'
                : 'Drag and drop a CSV file here, or click to select'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Maximum file size: 1MB
            </Typography>
          </>
        )}
        
        {uploadError && (
          <Typography variant="body2" color="error" textAlign="center">
            {uploadError}
          </Typography>
        )}
      </Box>
    </Paper>
  );
}

export default DropzoneArea; 