import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Paper,
    Button,
    Stack,
    Grid,
    Card,
    CardContent,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Chip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../supabaseClient';
import ParameterValues from './ParameterValues';

const PARAMETER_TYPES = [
    { value: 'boolean', label: 'Boolean' },
    { value: 'enum', label: 'Multiple Choice' },
    { value: 'number', label: 'Number' },
    { value: 'range', label: 'Range' }
];

const Parameters = ({ productId }) => {
    const { activeTenantId } = useAuth();
    const [parameters, setParameters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editingParameter, setEditingParameter] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        type: '',
        level: 1
    });
    const [expandedParameter, setExpandedParameter] = useState(null);

    useEffect(() => {
        fetchParameters();
    }, [productId]);

    const fetchParameters = async () => {
        try {
            const { data, error } = await supabase
                .from('dimensions')
                .select(`
                    *,
                    dimension_values (*)
                `)
                .eq('product_id', productId)
                .order('created_at', { ascending: true });

            if (error) throw error;
            setParameters(data || []);
        } catch (error) {
            console.error('Error fetching parameters:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (parameter = null) => {
        if (parameter) {
            setEditingParameter(parameter);
            setFormData({
                name: parameter.name,
                description: parameter.description || '',
                type: parameter.type,
                level: parameter.level || 1
            });
        } else {
            setEditingParameter(null);
            setFormData({
                name: '',
                description: '',
                type: '',
                level: 1
            });
        }
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setEditingParameter(null);
        setFormData({
            name: '',
            description: '',
            type: '',
            level: 1
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingParameter) {
                const { error } = await supabase
                    .from('dimensions')
                    .update({
                        name: formData.name,
                        description: formData.description,
                        type: formData.type,
                        level: formData.level,
                        tenant_id: activeTenantId
                    })
                    .eq('id', editingParameter.id);
                if (error) throw error;
            } else {
                const { error } = await supabase
                    .from('dimensions')
                    .insert([{
                        product_id: productId,
                        name: formData.name,
                        description: formData.description,
                        type: formData.type,
                        level: formData.level,
                        tenant_id: activeTenantId
                    }]);
                if (error) throw error;
            }
            handleCloseDialog();
            fetchParameters();
        } catch (error) {
            console.error('Error saving parameter:', error);
        }
    };

    const handleDelete = async (parameterId) => {
        if (window.confirm('Are you sure you want to delete this parameter?')) {
            try {
                const { error } = await supabase
                    .from('dimensions')
                    .delete()
                    .eq('id', parameterId);
                if (error) throw error;
                fetchParameters();
            } catch (error) {
                console.error('Error deleting parameter:', error);
            }
        }
    };

    const handleExpandParameter = (parameterId) => {
        setExpandedParameter(expandedParameter === parameterId ? null : parameterId);
    };

    if (loading) {
        return <Box sx={{ p: 3 }}>Loading...</Box>;
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">Parameters</Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenDialog()}
                >
                    Add Parameter
                </Button>
            </Box>

            <Grid container spacing={3}>
                {parameters.length === 0 ? (
                    <Grid item xs={12}>
                        <Paper 
                            sx={{ 
                                p: 3, 
                                textAlign: 'center',
                                backgroundColor: 'grey.100'
                            }}
                        >
                            <Typography variant="body1" color="textSecondary">
                                No parameters defined. Click the button above to add your first parameter.
                            </Typography>
                        </Paper>
                    </Grid>
                ) : (
                    parameters.map((parameter) => (
                        <Grid item xs={12} key={parameter.id}>
                            <Card>
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: parameter.description ? 2 : 0 }}>
                                        <Typography variant="h6" component="h2" sx={{ mr: 2 }}>
                                            {parameter.name}
                                        </Typography>
                                        <Chip 
                                            label={PARAMETER_TYPES.find(t => t.value === parameter.type)?.label} 
                                            size="small" 
                                            sx={{ mr: 2 }}
                                        />
                                        <Typography 
                                            variant="caption" 
                                            sx={{ 
                                                flex: 1,
                                                color: 'text.secondary'
                                            }}
                                        >
                                            Values: {parameter.dimension_values?.length || 0}
                                        </Typography>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleOpenDialog(parameter)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleDelete(parameter.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleExpandParameter(parameter.id)}
                                            >
                                                {expandedParameter === parameter.id ? 
                                                    <ExpandLessIcon /> : 
                                                    <ExpandMoreIcon />
                                                }
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {parameter.description && (
                                        <Typography variant="body2" color="textSecondary">
                                            {parameter.description}
                                        </Typography>
                                    )}
                                </CardContent>
                                {expandedParameter === parameter.id && (
                                    <Box sx={{ 
                                        borderTop: 1, 
                                        borderColor: 'divider',
                                        bgcolor: 'grey.50'
                                    }}>
                                        <ParameterValues parameterId={parameter.id} />
                                    </Box>
                                )}
                            </Card>
                        </Grid>
                    ))
                )}
            </Grid>

            {/* Parameter Dialog */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {editingParameter ? 'Edit Parameter' : 'New Parameter'}
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Stack spacing={3}>
                            <TextField
                                required
                                fullWidth
                                label="Parameter Name"
                                name="name"
                                value={formData.name}
                                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                            />
                            <TextField
                                fullWidth
                                label="Description"
                                name="description"
                                value={formData.description}
                                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                                multiline
                                rows={3}
                            />
                            <FormControl fullWidth required>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={formData.type}
                                    label="Type"
                                    onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
                                >
                                    {PARAMETER_TYPES.map((type) => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                type="number"
                                label="Level"
                                name="level"
                                value={formData.level}
                                onChange={(e) => setFormData(prev => ({ ...prev, level: parseInt(e.target.value) }))}
                                inputProps={{ min: 1 }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button type="submit" variant="contained">
                            {editingParameter ? 'Save' : 'Create'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
};

export default Parameters; 