import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    MenuItem,
    Button,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    CircularProgress,
    Stack
} from '@mui/material';
import { supabase } from '../../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

const UploadedFiles = () => {
    const { session, refreshToken, isTokenExpired, activeTenantId } = useAuth();
    const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null); // State to track the selected file
    const [selectedDate, setSelectedDate] = useState(null); // State for the date picker
    const [frequency, setFrequency] = useState('weekly'); // State for frequency dropdown
    const [projection, setProjection] = useState(1); // State for projection dropdown
    const [sumColumn, setSumColumn] = useState(''); // State for the Sum dropdown
    const [dateSumColumn, setDateSumColumn] = useState(''); // State for the Date Sum Column dropdown
    const [csvColumns, setCsvColumns] = useState([]); // State to hold CSV column names
    const [reportData, setReportData] = useState([]); // State to hold the report data
    const bucketName = 'csv_uploads'; // Replace with your actual bucket name
    const folderName = 'uploads';
    const [fileName, setFileName] = useState(null); // State to hold the file name
    const [uploading, setUploading] = useState(false); // State to track the upload process
    const [funnels, setFunnels] = useState([]);
    const [selectedFunnelId, setSelectedFunnelId] = useState('');
    const [loadingFunnels, setLoadingFunnels] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (selectedFunnelId) {
            fetchUploadedFiles();
        } else {
            setUploadedFiles([]); // Clear files when no funnel is selected
        }
    }, [selectedFunnelId]); // Add selectedFunnelId as dependency

    const fetchUploadedFiles = async () => {
        try {
            // Guard against empty funnel ID or missing tenant ID
            if (!selectedFunnelId || !activeTenantId) {
                setUploadedFiles([]);
                return;
            }

            console.log('Fetching file record for funnel:', selectedFunnelId);
            const { data: fileRecord, error: fileError } = await supabase
                .from('funnel_hubspot_files')
                .select('file_path')
                .eq('funnel_id', selectedFunnelId)
                .single();

            if (fileError) {
                console.error('Error fetching file record:', fileError);
                setUploadedFiles([]);
                return;
            }

            if (!fileRecord) {
                setUploadedFiles([]);
                console.log('No file record found');
                return;
            }

            // Then get the file details from storage
            const { data: fileData, error: storageError } = await supabase
                .storage
                .from(bucketName)
                .list(activeTenantId, {
                    search: fileRecord.file_path.split('/').pop() // Get just the filename
                });

            console.log('fileData:', fileData);

            if (storageError) {
                console.error('Error fetching files:', storageError);
                setUploadedFiles([]);
                return;
            }

            setUploadedFiles(fileData || []);
        } catch (error) {
            console.error('Error in fetchUploadedFiles:', error);
            setUploadedFiles([]);
        }
    };

    useEffect(() => {
        fetchFunnels();
    }, [activeTenantId]); // Add activeTenantId as dependency

    const fetchFunnels = async () => {
        try {
            setLoadingFunnels(true);
            if (!activeTenantId) {
                throw new Error('No tenant ID found');
            }

            const { data, error } = await supabase
                .from('funnels')
                .select('id, name')
                .eq('tenant_id', activeTenantId);

            if (error) throw error;
            setFunnels(data || []);
        } catch (error) {
            console.error('Error fetching funnels:', error);
        } finally {
            setLoadingFunnels(false);
        }
    };

    // Handle file download
    const handleDownload = async (fileName) => {
        const fullPath = `${activeTenantId}/${fileName}`;
        const { data, error } = await supabase
            .storage
            .from(bucketName)
            .download(fullPath); // Download the file by its full path

        if (error) {
            console.error('Error downloading file:', error);
            return;
        }

        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName; // Use the file name for download
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    // Handle row click
    const handleRowClick = async (file) => {
        setSelectedFile(file); // Set the selected file
        await fetchCsvColumns(file.name); // Fetch CSV columns when a file is selected
    };

    // Fetch CSV columns from the selected file
    const fetchCsvColumns = async (fileName) => {
        const fullPath = `${activeTenantId}/${fileName}`;
        const { data, error } = await supabase
            .storage
            .from(bucketName)
            .download(fullPath); // Download the file to read its content

        if (error) {
            console.error('Error downloading file for column extraction:', error);
            return;
        }

        const text = await data.text(); // Read the file as text
        const rows = text.split('\n'); // Split the text into rows
        const headers = rows[0].split(',').map(header => header.replace(/^"|"$/g, '').trim()); // Remove quotes and trim whitespace
        setCsvColumns(headers); // Set the CSV columns
    };

    // Function to handle report creation
    const handleCreateReport = async () => {
        if (!selectedFile) {
            console.error('No file selected');
            return;
        }

        const fullPath = `${activeTenantId}/${selectedFile.name}`;
        console.log('fullPath:', fullPath);

        try {
            if (isTokenExpired(session)) {
                await refreshToken();
            }

            const response = await fetch(`${API_URL}/api/reports/cohort`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session?.access_token}`
                },
                body: JSON.stringify({
                    full_path: fullPath,
                    start_date: selectedDate,
                    pace: frequency,
                    laps: projection,
                    summ_column_name: sumColumn,
                    summ_column_event_date: dateSumColumn,
                    tenant_id: activeTenantId,
                    funnel_id: selectedFunnelId
                })
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => ({}));
                throw new Error(errorData.detail || 'Failed to create report');
            }

            const result = await response.json();
            console.log('Report created successfully:', result);

            let parsedData;
            try {
                parsedData = typeof result === 'string' ? JSON.parse(result) : result;
            } catch (parseError) {
                console.error('Error parsing result:', parseError);
                parsedData = [];
            }

            if (Array.isArray(parsedData)) {
                setReportData(parsedData);
            } else {
                console.error('Expected an array but got:', typeof parsedData);
                setReportData([]);
            }

        } catch (error) {
            console.error('Error creating report:', error);
            setReportData([]);
        }
    };

    // Handle file change
    const handleFileChange = async (event) => {
        try {
            const file = event.target.files[0];
            if (!file) return;

            setFileName(file.name);
            setUploading(true);

            const { error } = await supabase.storage
                .from(bucketName)
                .upload(`${folderName}/${file.name}`, file);

            if (error) {
                throw error;
            }

            // Refresh the file list
            const { data, error: listError } = await supabase
                .storage
                .from(bucketName)
                .list(`${folderName}`);

            if (listError) {
                throw listError;
            }

            setUploadedFiles(data);
            setFileName('');
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setUploading(false);
        }
    };

    // Handle funnel selection change
    const handleFunnelChange = async (event) => {
        const newFunnelId = event.target.value || ''; // Ensure we don't pass null
        setSelectedFunnelId(newFunnelId);

        if (!newFunnelId) {
            setUploadedFiles([]); // Clear files if no funnel selected
            setSelectedFile(null);
            setSelectedDate(null);
            setFrequency('weekly');
            setProjection(1);
            setSumColumn('');
            setDateSumColumn('');
            setCsvColumns([]);
            return;
        }

        // Rest of the clear operations
        setSelectedFile(null);
        setSelectedDate(null);
        setFrequency('weekly');
        setProjection(1);
        setSumColumn('');
        setDateSumColumn('');
        setCsvColumns([]);
    };

    // Render the table for uploaded files
    return (
        <Box sx={{ p: 2.5 }}>
            {/* Top section with two columns */}
            <Stack
                direction="row"
                spacing={2.5}
                sx={{ mb: 2.5 }}
            >
                {/* Left column - Generate Report Form */}
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Configure Your Report</Typography>
                    <Stack spacing={2}>
                        {/* First Row - Selected File */}
                        <TextField
                            label="Selected File"
                            value={selectedFile ? selectedFile.name : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            size="small"
                        />

                        {/* Second Row - Date, Frequency, Projection */}
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Select Date"
                                type="date"
                                value={selectedDate || ''}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                fullWidth
                            />
                            <TextField
                                select
                                label="Frequency"
                                value={frequency}
                                onChange={(e) => setFrequency(e.target.value)}
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="weekly">Weekly</MenuItem>
                                <MenuItem value="monthly">Monthly</MenuItem>
                                <MenuItem value="quarterly">Quarterly</MenuItem>
                            </TextField>
                            <TextField
                                select
                                label="Projection"
                                value={projection}
                                onChange={(e) => setProjection(e.target.value)}
                                size="small"
                                fullWidth
                            >
                                {[...Array(12)].map((_, index) => (
                                    <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                                ))}
                            </TextField>
                        </Stack>

                        {/* Third Row - Sum, Sum Date */}
                        <Stack direction="row" spacing={2}>
                            <TextField
                                select
                                label="Amount To Calculate"
                                value={sumColumn}
                                onChange={(e) => setSumColumn(e.target.value)}
                                size="small"
                                fullWidth
                            >
                                {csvColumns.map((column, index) => (
                                    <MenuItem key={index} value={column}>{column}</MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Event for Amount Calculation"
                                value={dateSumColumn}
                                onChange={(e) => setDateSumColumn(e.target.value)}
                                size="small"
                                fullWidth
                            >
                                {csvColumns.map((column, index) => (
                                    <MenuItem key={index} value={column}>{column}</MenuItem>
                                ))}
                            </TextField>
                        </Stack>

                        <Button
                            variant="contained"
                            onClick={handleCreateReport}
                            disabled={!selectedFile}
                        >
                            Generate Report
                        </Button>
                    </Stack>
                </Box>

                {/* Right column - File Upload and List */}
                <Box sx={{ flex: 1 }}>
                    {/* File Upload Section */}
                    <Box sx={{ mb: 3 }}>
                        <Typography
                            variant="h6"
                            sx={{ mb: 2}}
                        >
                            Select Dataset or <span onClick={() => navigate('/reports/funnels')} style={{ cursor: 'pointer', color: 'blue' }}><i>click here</i></span> to create a new one
                        </Typography>
                        <FormControl fullWidth size="small">
                            <InputLabel>Funnel</InputLabel>
                            <Select
                                value={selectedFunnelId}
                                onChange={handleFunnelChange}
                                disabled={loadingFunnels}
                            >
                                {funnels.map((funnel) => (
                                    <MenuItem key={funnel.id} value={funnel.id}>
                                        {funnel.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {loadingFunnels && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                <CircularProgress size={20} />
                            </Box>
                        )}
                    </Box>

                    {/* Files Table */}
                    <Typography variant="h6" sx={{ mb: 2 }}>Uploaded Files</Typography>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {uploadedFiles.map(file => (
                                    <TableRow
                                        key={file.name}
                                        onClick={() => handleRowClick(file)}
                                        sx={{
                                            cursor: 'pointer',
                                            backgroundColor: selectedFile?.name === file.name ? '#f5f5f5' : 'inherit'
                                        }}
                                    >
                                        <TableCell>{file.name}</TableCell>
                                        <TableCell>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={(e) => { e.stopPropagation(); handleDownload(file.name); }}
                                                sx={{ mr: 1 }}
                                            >
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Stack>

            {/* Bottom section - Report Data */}
            {Array.isArray(reportData) && reportData.length > 0 && (
                <Box sx={{ mt: 2.5 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Report Data</Typography>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#1976d2',
                                            fontSize: '0.9rem',
                                            textAlign: 'center',
                                            borderBottom: '2px solid #1976d2'
                                        }}
                                    >
                                        Stage
                                    </TableCell>
                                    {Object.keys(reportData[0]).filter(key => key !== 'index').map((key) => (
                                        <TableCell
                                            key={key}
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#1976d2',
                                                fontSize: '0.9rem',
                                                textAlign: 'center',
                                                borderBottom: '2px solid #1976d2'
                                            }}
                                        >
                                            {key}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {row.index}
                                        </TableCell>
                                        {Object.entries(row)
                                            .filter(([key]) => key !== 'index')
                                            .map(([key, value], idx) => (
                                                <TableCell
                                                    key={idx}
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    {value}
                                                </TableCell>
                                            ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};

export default UploadedFiles;
