import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Alert,
  Typography,
  Button,
  Modal,
  Box,
  Autocomplete,
  TextField,
  IconButton,
  Collapse,
} from '@mui/material';
import moment from 'moment';
import { useAuth } from '../../../context/AuthContext';
import { useParams, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const WorkflowDetails = () => {
  const [tenants, setTenants] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataLoading, setDataLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [addingTenant, setAddingTenant] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  
  const { session, loading: authLoading } = useAuth();
  const { workflowId } = useParams();
  const location = useLocation();
  const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

  

  const columns = [
    { id: 'tenant_name', label: 'Tenant Name' },
    { id: 'tenant_id', label: 'Tenant ID' },
    { 
      id: 'added_at', 
      label: 'Added At',
      format: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    { 
      id: 'meta', 
      label: 'Meta',
      format: (value) => JSON.stringify(value),
    },
  ];

  const fetchTenants = async () => {
    const urlWorkflowId = workflowId || location.pathname.split('/').pop();
    
    if (!urlWorkflowId) {
      setError('No workflow ID provided');
      setDataLoading(false);
      return;
    }

    if (!session?.access_token) {
      setDataLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/custom-workflows/get-workflow-tenants?${new URLSearchParams({
        workflow_id: urlWorkflowId
      })}`, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch tenant data');
      }

      const data = await response.json();
      setTenants(data.tenants || []);
    } catch (err) {
      setError('Failed to fetch tenant data');
      console.error('Error fetching tenants:', err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    setDataLoading(true);
    
    if (!authLoading) {
      fetchTenants();
    }
  }, [workflowId, session?.access_token, API_URL, authLoading]);

  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchTenants = async (searchTerm) => {
    if (!searchTerm || searchTerm.length < 2) {
      setSearchResults([]);
      return;
    }

    setSearchLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/users/searchtenants?${new URLSearchParams({
        query: searchTerm
      })}`, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to search tenants');
      
      const data = await response.json();
      setSearchResults(data || []);
    } catch (error) {
      console.error('Error searching tenants:', error);
      setSearchResults([]);
    } finally {
      setSearchLoading(false);
    }
  };

  console.log('Current search results:', searchResults);

  const handleAddTenant = async () => {
    if (!selectedTenant || !workflowId) return;

    setAddingTenant(true);
    try {
      const response = await fetch(`${API_URL}/api/custom-workflows/add-workflow-tenant`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          workflow_id: workflowId,
          tenant_id: selectedTenant.id
        })
      });

      if (!response.ok) throw new Error('Failed to add tenant to workflow');

      // Refresh the tenants list
      fetchTenants();
      setOpenModal(false);
      setSelectedTenant(null);
    } catch (error) {
      console.error('Error adding tenant to workflow:', error);
    } finally {
      setAddingTenant(false);
    }
  };

  const handleExpandClick = (tenantId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [tenantId]: !prev[tenantId],
    }));
  };

  if (authLoading || dataLoading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="400px" // Gives enough space for the spinner
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">
          Workflow Access Details
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenModal(true)}
        >
          Add Tenant Access
        </Button>
      </Box>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tenant) => (
                <React.Fragment key={tenant.tenant_id}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {column.id === 'meta' ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" sx={{ mr: 1 }}>
                              View Metadata
                            </Typography>
                            <ExpandMore
                              expand={expandedRows[tenant.tenant_id]}
                              onClick={() => handleExpandClick(tenant.tenant_id)}
                              aria-expanded={expandedRows[tenant.tenant_id]}
                              aria-label="show more"
                              size="small"
                            >
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </Box>
                        ) : (
                          column.format
                            ? column.format(tenant[column.id])
                            : tenant[column.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell 
                      style={{ paddingBottom: 0, paddingTop: 0 }} 
                      colSpan={columns.length}
                    >
                      <Collapse in={expandedRows[tenant.tenant_id]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                          <Typography variant="subtitle2" gutterBottom>
                            Metadata:
                          </Typography>
                          <Paper 
                            variant="outlined"
                            sx={{
                              backgroundColor: (theme) => theme.palette.grey[900],
                              color: (theme) => theme.palette.common.white,
                              p: 2,
                              borderRadius: 1,
                              overflow: 'auto'
                            }}
                          >
                            <pre style={{ margin: 0, fontSize: '0.875rem' }}>
                              {JSON.stringify(tenant.meta, null, 2)}
                            </pre>
                          </Paper>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tenants.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setSelectedTenant(null);
        }}
        aria-labelledby="add-tenant-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Add Tenant Access
          </Typography>
          <Autocomplete
            options={searchResults}
            getOptionLabel={(option) => `${option.name} (${option.id})`}
            loading={searchLoading}
            value={selectedTenant}
            onChange={(event, newValue) => {
              console.log('Selected tenant:', newValue);
              setSelectedTenant(newValue);
            }}
            onInputChange={(event, newValue) => {
              handleSearchTenants(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Tenants"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography noWrap>
                  {option.name} ({option.id})
                </Typography>
              </li>
            )}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleAddTenant}
              disabled={!selectedTenant || addingTenant}
            >
              {addingTenant ? <CircularProgress size={24} /> : 'Add'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Paper>
  );
};

export default WorkflowDetails;