import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../supabaseClient';

const ICPSegments = ({ productId, businessId }) => {
    const navigate = useNavigate();
    const { activeTenantId } = useAuth();
    const [segments, setSegments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchSegments();
    }, [productId]);

    const fetchSegments = async () => {
        try {
            const { data, error } = await supabase
                .from('icp_segments')
                .select('*')
                .eq('product_id', productId)
                .order('created_at', { ascending: false });

            if (error) throw error;
            setSegments(data || []);
        } catch (error) {
            console.error('Error fetching ICP segments:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (segmentId) => {
        if (window.confirm('Are you sure you want to delete this ICP segment?')) {
            try {
                const { error } = await supabase
                    .from('icp_segments')
                    .delete()
                    .eq('id', segmentId);
                if (error) throw error;
                fetchSegments();
            } catch (error) {
                console.error('Error deleting ICP segment:', error);
            }
        }
    };

    const handleAddSegment = () => {
        navigate(`/business/configure/business/${businessId}/product/${productId}/segments/new`);
    };

    const handleEditSegment = (segmentId) => {
        navigate(`/business/configure/business/${businessId}/product/${productId}/segments/${segmentId}/edit`);
    };

    if (loading) {
        return <Box sx={{ p: 3 }}>Loading...</Box>;
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">ICP Segments</Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddSegment}
                >
                    Add ICP Segment
                </Button>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {segments.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center" sx={{ py: 3 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        No ICP segments defined. Click the button above to add your first segment.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            segments.map((segment) => (
                                <TableRow key={segment.id}>
                                    <TableCell>{segment.icp_name}</TableCell>
                                    <TableCell>{segment.description}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditSegment(segment.id)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDelete(segment.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ICPSegments; 