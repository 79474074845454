import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import PsychologyIcon from '@mui/icons-material/Psychology';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

const ACTION_TYPES = [
    {
        type: 'scrape',
        name: 'Web Scraping',
        description: 'Scrape data from websites',
        icon: <LanguageIcon />
    },
    {
        type: 'openai',
        name: 'OpenAI',
        description: 'Process data using OpenAI models',
        icon: <PsychologyIcon />
    },
    {
        type: 'get_web_tech',
        name: 'Web Technology',
        description: 'Detect technologies used on websites',
        icon: <DeveloperBoardIcon />
    }
];

const AddActionModal = ({ open, onClose, onSelectAction }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Action</DialogTitle>
            <DialogContent>
                <List>
                    {ACTION_TYPES.map((action) => (
                        <ListItem key={action.type} disablePadding>
                            <ListItemButton 
                                onClick={() => {
                                    onSelectAction(action.type);
                                    onClose();
                                }}
                            >
                                <ListItemIcon>
                                    {action.icon}
                                </ListItemIcon>
                                <ListItemText 
                                    primary={action.name}
                                    secondary={action.description}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default AddActionModal; 