import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Paper,
    TextField,
    Button,
    Stack
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../context/AuthContext';
import Parameters from './Parameters';
import ICPSegments from './ICPSegments';

const Product = () => {
    const { businessId, productId } = useParams();
    const navigate = useNavigate();
    const isNew = !productId || productId === 'new';
    const { activeTenantId } = useAuth();
    
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });
    const [loading, setLoading] = useState(!isNew);

    useEffect(() => {
        if (!isNew && productId) {
            fetchProduct();
        }
    }, [productId]);

    const fetchProduct = async () => {
        try {
            const { data, error } = await supabase
                .from('products')
                .select('*')
                .eq('id', productId)
                .single();

            if (error) throw error;
            if (data) {
                setFormData({
                    name: data.name,
                    description: data.description || '',
                    tenant_id: activeTenantId
                });
            }
        } catch (error) {
            console.error('Error fetching product:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isNew) {
                const { error } = await supabase
                    .from('products')
                    .insert([{
                        business_id: businessId,
                        name: formData.name,
                        description: formData.description,
                        tenant_id: activeTenantId
                    }]);
                if (error) throw error;
            } else {
                const { error } = await supabase
                    .from('products')
                    .update({
                        name: formData.name,
                        description: formData.description,
                        tenant_id: activeTenantId
                    })
                    .eq('id', productId);
                if (error) throw error;
            }
            navigate(`/leadgen/configure/business/${businessId}`);
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    if (loading) {
        return <Box sx={{ p: 3 }}>Loading...</Box>;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5" component="h1" gutterBottom>
                {isNew ? 'New Product' : 'Edit Product'}
            </Typography>
            
            <Paper sx={{ p: 3, mt: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <TextField
                            required
                            fullWidth
                            label="Product Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                            <Button 
                                variant="outlined" 
                                onClick={() => navigate(`/leadgen/configure/business/${businessId}`)}
                            >
                                Cancel
                            </Button>
                            <Button 
                                type="submit" 
                                variant="contained"
                            >
                                {isNew ? 'Create' : 'Save'}
                            </Button>
                        </Box>
                    </Stack>
                </form>
            </Paper>

            {/* Only show Parameters and ICP Segments when editing an existing product */}
            {!isNew && (
                <>
                    <Parameters productId={productId} />
                    <ICPSegments productId={productId} />
                </>
            )}
        </Box>
    );
};

export default Product; 