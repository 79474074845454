import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Paper,
    TextField,
    Button,
    Stack,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    Divider
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../supabaseClient';

const Business = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { activeTenantId } = useAuth();
    const isNew = !id || id === 'new';
    
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(!isNew);

    useEffect(() => {
        if (!isNew && id) {
            fetchBusiness();
            fetchProducts();
        }
    }, [id]);

    const fetchBusiness = async () => {
        try {
            const { data, error } = await supabase
                .from('businesses')
                .select('*')
                .eq('id', id)
                .single();

            if (error) throw error;
            if (data) {
                setFormData({
                    name: data.name,
                    description: data.description || ''
                });
            }
        } catch (error) {
            console.error('Error fetching business:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchProducts = async () => {
        try {
            const { data, error } = await supabase
                .from('products')
                .select('*')
                .eq('business_id', id);

            if (error) throw error;
            setProducts(data || []);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isNew) {
                const { error } = await supabase
                    .from('businesses')
                    .insert([{
                        tenant_id: activeTenantId,
                        name: formData.name,
                        description: formData.description
                    }]);
                if (error) throw error;
            } else {
                const { error } = await supabase
                    .from('businesses')
                    .update({
                        name: formData.name,
                        description: formData.description
                    })
                    .eq('id', id);
                if (error) throw error;
            }
            navigate('/leadgen/configure');
        } catch (error) {
            console.error('Error saving business:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddProduct = () => {
        navigate(`/business/configure/business/${id}/product/new`);
    };

    const handleProductClick = (productId) => {
        navigate(`/business/configure/business/${id}/product/${productId}`);
    };

    if (loading) {
        return <Box sx={{ p: 3 }}>Loading...</Box>;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5" component="h1" gutterBottom>
                {isNew ? 'New Business' : 'Edit Business'}
            </Typography>
            
            {/* Business Form */}
            <Paper sx={{ p: 3, mt: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <TextField
                            required
                            fullWidth
                            label="Business Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                            <Button 
                                variant="outlined" 
                                onClick={() => navigate('/business/configure')}
                            >
                                Cancel
                            </Button>
                            <Button 
                                type="submit" 
                                variant="contained"
                            >
                                {isNew ? 'Create' : 'Save'}
                            </Button>
                        </Box>
                    </Stack>
                </form>
            </Paper>

            {/* Products Section - Only show if not a new business */}
            {!isNew && (
                <Box sx={{ mt: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6">Products</Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleAddProduct}
                        >
                            Add Product
                        </Button>
                    </Box>
                    
                    <Grid container spacing={3}>
                        {products.length === 0 ? (
                            <Grid item xs={12}>
                                <Paper 
                                    sx={{ 
                                        p: 3, 
                                        textAlign: 'center',
                                        backgroundColor: 'grey.100'
                                    }}
                                >
                                    <Typography variant="body1" color="textSecondary">
                                        No products found. Click the button above to add your first product.
                                    </Typography>
                                </Paper>
                            </Grid>
                        ) : (
                            products.map((product) => (
                                <Grid item xs={12} sm={6} md={4} key={product.id}>
                                    <Card>
                                        <CardActionArea 
                                            onClick={() => handleProductClick(product.id)}
                                        >
                                            <CardContent>
                                                <Typography variant="h6" component="h2" gutterBottom>
                                                    {product.name}
                                                </Typography>
                                                {product.description && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        {product.description}
                                                    </Typography>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default Business; 