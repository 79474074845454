import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Paper,
    TextField,
    Button,
    Stack,
    Grid,
    List,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    Chip
} from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../supabaseClient';

// Create a draggable chip component
const DraggableChip = ({ value, parameter }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'dimension-value',
        item: {
            valueId: value.id,
            value: value.value,
            parameterId: parameter.id,
            parameterName: parameter.name
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    return (
        <div ref={drag} style={{ display: 'inline-block', opacity: isDragging ? 0.5 : 1 }}>
            <Chip
                label={value.value}
                size="small"
                sx={{ 
                    m: 0.5,
                    cursor: 'move',
                    '&:hover': {
                        backgroundColor: 'primary.light'
                    }
                }}
            />
        </div>
    );
};

const ICPSegmentForm = () => {
    const { businessId, productId, segmentId } = useParams();
    const navigate = useNavigate();
    const { activeTenantId } = useAuth();
    const isNew = !segmentId || segmentId === 'new';
    
    const [formData, setFormData] = useState({
        icp_name: '',
        description: '',
        definition: {}
    });
    const [parameters, setParameters] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [loading, setLoading] = useState(!isNew);

    useEffect(() => {
        fetchParameters();
        if (!isNew) {
            fetchSegment();
        }
    }, [segmentId]);

    useEffect(() => {
        if (!isNew && parameters.length > 0 && formData.definition) {
            transformDefinitionToSelectedValues();
        }
    }, [parameters, formData.definition]);

    const fetchParameters = async () => {
        try {
            const { data, error } = await supabase
                .from('dimensions')
                .select(`
                    *,
                    dimension_values (*)
                `)
                .eq('product_id', productId)
                .order('created_at', { ascending: true });

            if (error) throw error;
            console.log('Fetched parameters:', data);
            setParameters(data || []);
        } catch (error) {
            console.error('Error fetching parameters:', error);
        }
    };

    const fetchSegment = async () => {
        try {
            const { data, error } = await supabase
                .from('icp_segments')
                .select('*')
                .eq('id', segmentId)
                .single();

            if (error) throw error;
            if (data) {
                setFormData({
                    icp_name: data.icp_name,
                    description: data.description || '',
                    definition: data.definition || {}
                });
            }
        } catch (error) {
            console.error('Error fetching ICP segment:', error);
        } finally {
            setLoading(false);
        }
    };

    const transformDefinitionToSelectedValues = () => {
        const values = [];
        for (const [paramName, paramValue] of Object.entries(formData.definition)) {
            // Find the parameter details
            const parameter = parameters.find(p => p.name === paramName);
            if (parameter) {
                if (Array.isArray(paramValue)) {
                    // Handle multiple values
                    paramValue.forEach(value => {
                        const dimensionValue = parameter.dimension_values?.find(v => v.value === value);
                        if (dimensionValue) {
                            values.push({
                                parameterId: parameter.id,
                                parameterName: paramName,
                                valueId: dimensionValue.id,
                                value: value
                            });
                        }
                    });
                } else {
                    // Handle single value
                    const dimensionValue = parameter.dimension_values?.find(v => v.value === paramValue);
                    if (dimensionValue) {
                        values.push({
                            parameterId: parameter.id,
                            parameterName: paramName,
                            valueId: dimensionValue.id,
                            value: paramValue
                        });
                    }
                }
            }
        }
        setSelectedValues(values);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Transform selectedValues into the definition format
            const definition = selectedValues.reduce((acc, curr) => {
                // If parameter already exists in definition, make it an array if it isn't already
                if (acc[curr.parameterName]) {
                    if (Array.isArray(acc[curr.parameterName])) {
                        acc[curr.parameterName].push(curr.value);
                    } else {
                        // Convert existing single value to array with both values
                        acc[curr.parameterName] = [acc[curr.parameterName], curr.value];
                    }
                } else {
                    // First value for this parameter
                    acc[curr.parameterName] = curr.value;
                }
                return acc;
            }, {});

            const segmentData = {
                icp_name: formData.icp_name,
                description: formData.description,
                definition: definition,
                product_id: productId,
                tenant_id: activeTenantId
            };

            if (isNew) {
                const { data, error } = await supabase
                    .from('icp_segments')
                    .insert([segmentData])
                    .select()
                    .single();

                if (error) throw error;
                console.log('Created new segment:', data);
            } else {
                const { data, error } = await supabase
                    .from('icp_segments')
                    .update(segmentData)
                    .eq('id', segmentId)
                    .select()
                    .single();

                if (error) throw error;
                console.log('Updated segment:', data);
            }

            navigate(`/business/configure/business/${businessId}/product/${productId}`);
        } catch (error) {
            console.error('Error saving ICP segment:', error);
            // You might want to show an error message to the user here
        }
    };

    const removeSelectedValue = (item) => {
        // First update selectedValues
        setSelectedValues(prev => {
            const newSelectedValues = prev.filter(v => 
                !(v.parameterId === item.parameterId && v.valueId === item.valueId)
            );

            // Then update formData based on the new selectedValues
            setFormData(prevForm => {
                const newDefinition = { ...prevForm.definition };
                
                // Check if there are any other values for this parameter in the new selectedValues
                const hasOtherValuesForParameter = newSelectedValues.some(v => 
                    v.parameterName === item.parameterName
                );

                if (!hasOtherValuesForParameter) {
                    delete newDefinition[item.parameterName];
                } else {
                    // Update the definition with remaining values
                    const remainingValues = newSelectedValues
                        .filter(v => v.parameterName === item.parameterName)
                        .map(v => v.value);
                    
                    newDefinition[item.parameterName] = remainingValues.length === 1 
                        ? remainingValues[0]  // Single value
                        : remainingValues;    // Array of values
                }

                return {
                    ...prevForm,
                    definition: newDefinition
                };
            });

            return newSelectedValues;
        });
    };

    // Add the drop target functionality
    const [{ isOver }, drop] = useDrop({
        accept: 'dimension-value',
        drop: (item) => {
            const newValue = {
                parameterId: item.parameterId,
                parameterName: item.parameterName,
                valueId: item.valueId,
                value: item.value
            };
            
            const valueExists = selectedValues.some(
                v => v.parameterId === newValue.parameterId && v.valueId === newValue.valueId
            );
            
            if (!valueExists) {
                setSelectedValues(prev => [...prev, newValue]);
                setFormData(prev => ({
                    ...prev,
                    definition: {
                        ...prev.definition,
                        [newValue.parameterName]: newValue.value
                    }
                }));
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    if (loading) {
        return <Box sx={{ p: 3 }}>Loading...</Box>;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5" component="h1" gutterBottom>
                {isNew ? 'New ICP Segment' : 'Edit ICP Segment'}
            </Typography>
            
            <Paper sx={{ p: 3, mt: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <TextField
                            required
                            fullWidth
                            label="Segment Name"
                            name="icp_name"
                            value={formData.icp_name}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                    </Stack>
                </form>
            </Paper>

            <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                    <Paper 
                        ref={drop}
                        sx={{ 
                            p: 3, 
                            height: '400px', 
                            overflowY: 'auto',
                            backgroundColor: isOver ? '#f0f0f0' : 'white',
                            transition: 'background-color 0.2s ease',
                            border: isOver ? '2px dashed #1976d2' : '2px solid transparent'
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Selected Values
                        </Typography>
                        <List>
                            {selectedValues.map((item) => (
                                <ListItem 
                                    key={`${item.parameterId}-${item.valueId}`}
                                    secondaryAction={
                                        <Button 
                                            size="small" 
                                            onClick={() => removeSelectedValue(item)}
                                        >
                                            Remove
                                        </Button>
                                    }
                                >
                                    <ListItemText 
                                        primary={`${item.parameterName}: ${item.value}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{ p: 3, height: '400px', overflowY: 'auto' }}>
                        <Typography variant="h6" gutterBottom>
                            Available Parameters
                        </Typography>
                        <Stack spacing={2}>
                            {parameters.map((parameter) => (
                                <Card key={parameter.id} variant="outlined">
                                    <CardContent>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {parameter.name}
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {parameter.dimension_values?.map((value) => (
                                                <DraggableChip
                                                    key={value.id}
                                                    value={value}
                                                    parameter={parameter}
                                                />
                                            ))}
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))}
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>

            <Box sx={{ mt: 3, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                <Button 
                    variant="outlined" 
                    onClick={() => navigate(`/business/configure/business/${businessId}/product/${productId}`)}
                >
                    Cancel
                </Button>
                <Button 
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {isNew ? 'Create' : 'Save'}
                </Button>
            </Box>
        </Box>
    );
};

export default ICPSegmentForm; 