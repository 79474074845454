import React from 'react';
import { List, ListItem, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';
import DataObjectIcon from '@mui/icons-material/DataObject';
import WorkflowIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth } from '../../context/AuthContext';
import { styled } from '@mui/material/styles';

const SidebarMenuItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    transition: 'all 0.2s ease',

    '.collapsed &': {
        padding: theme.spacing(1.5, 0),
        justifyContent: 'center',
        marginLeft: 0,
        marginRight: 0
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.primary.main
    },

    '&.active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.selected,
        fontWeight: 500,

        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main
        }
    }
}));

const MenuText = styled('span')(({ theme }) => ({
    whiteSpace: 'nowrap',
    opacity: 1,
    transition: 'opacity 0.2s ease',
    '.collapsed &': {
        opacity: 0,
        width: 0,
        display: 'none'
    }
}));

const MenuIconWrapper = styled(Box)(({ theme }) => ({
    minWidth: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit'
}));

const SidebarMenu = () => {
    const location = useLocation();
    const { permissions } = useAuth();

    const menuItems = [
        {
            path: '/crm',
            icon: <BusinessIcon />,
            text: 'CRM',
            requiredPermission: 'View CRM2'
        },
        {
            path: '/business',
            icon: <CampaignIcon />,
            text: 'Business Model',
            requiredPermission: 'View lead generation config2'
        },
        {
            path: '/reports',
            icon: <AssessmentIcon />,
            text: 'Reports',
            requiredPermission: 'View reports2'
        },
        {
            path: '/enrichment',
            icon: <DataObjectIcon />,
            text: 'Data Enrichment',
            requiredPermission: 'Run mates'
        },
        {
            path: '/mate-owners',
            icon: <GroupIcon />,
            text: 'Mate Owners',
            requiredPermission: 'View mate owners'
        },
        {
            path: '/mates',
            icon: <PersonIcon />,
            text: 'Mates',
            requiredPermission: 'View mate types'
        },
        {
            path: '/custom-workflows',
            icon: <PersonIcon />,
            text: 'Custom Workflows',
            requiredPermission: 'Manage Custom N8N Workflows'
        },
        {
            path: '/my-custom-workflows',
            icon: <WorkflowIcon />,
            text: 'My Custom Workflows',
            requiredPermission: 'View Custom N8N Workflows'
        }
    ];

    // Filter menu items based on permissions
    const authorizedMenuItems = menuItems.filter(item => 
        permissions.includes(item.requiredPermission)
    );

    return (
        <List sx={{ p: 0 }}>
            {authorizedMenuItems.map((item) => (
                <SidebarMenuItem
                    key={item.path}
                    component={Link}
                    to={item.path}
                    className={location.pathname === item.path ? 'active' : ''}
                >
                    <MenuIconWrapper>
                        {item.icon}
                    </MenuIconWrapper>
                    <MenuText>{item.text}</MenuText>
                </SidebarMenuItem>
            ))}
        </List>
    );
};

export default SidebarMenu; 