import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Alert,
    Chip,
    TablePagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../context/AuthContext';
import dayjs from 'dayjs';

const WorkflowRun = () => {
    const { id: canonicalId } = useParams();
    const navigate = useNavigate();
    const { activeTenantId, session } = useAuth();
    const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
    
    const [workflow, setWorkflow] = useState(null);
    const [batchProcessings, setBatchProcessings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    
    // File upload states
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [uploadedFilePath, setUploadedFilePath] = useState(null);
    const [isRunning, setIsRunning] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState({
        dateFrom: null,
        dateTo: null,
        sourceType: ''
    });

    useEffect(() => {
        fetchWorkflow();
    }, [canonicalId]);

    useEffect(() => {
        // Only fetch batch processings when workflow data is available
        if (workflow) {
            fetchBatchProcessings();
        }
    }, [page, rowsPerPage, filters, canonicalId, workflow]);

    const fetchBatchProcessings = async () => {
        try {
            const workflowIds = workflow?.map(w => w.id) || [];
            
            let query = supabase
                .from('batch_processings')
                .select(`
                    *,
                    users (
                        email
                    ),
                    record_processings (
                        status
                    ),
                    workflow:icp_segment_enrichment_workflows (
                        version,
                        name
                    )
                `)
                .in('workflow_id', workflowIds)
                .eq('tenant_id', activeTenantId)
                .order('created_at', { ascending: false })
                .range(page * rowsPerPage, (page + 1) * rowsPerPage - 1);

            // Apply filters
            if (filters.sourceType) {
                query = query.eq('source_type', filters.sourceType);
            }
            if (filters.dateFrom) {
                query = query.gte('created_at', filters.dateFrom.toISOString());
            }
            if (filters.dateTo) {
                const endDate = dayjs(filters.dateTo).endOf('day');
                query = query.lte('created_at', endDate.toISOString());
            }

            const { data, error } = await query;

            if (error) throw error;

            // Process the data to count statuses
            const processedData = data?.map(batch => ({
                ...batch,
                workflowVersion: batch.workflow?.version,
                statusCounts: {
                    total: batch.total_records,
                    completed: batch.record_processings?.filter(r => r.status === 'COMPLETED').length || 0,
                    completed_stopped: batch.record_processings?.filter(r => r.status === 'COMPLETED_STOPPED').length || 0,
                    failed: batch.record_processings?.filter(r => r.status === 'FAILED').length || 0,
                    pending: batch.record_processings?.filter(r => r.status === 'PENDING').length || 0,
                    running: batch.record_processings?.filter(r => r.status === 'RUNNING').length || 0
                }
            })) || [];

            setBatchProcessings(processedData);
        } catch (error) {
            console.error('Error fetching batch processings:', error);
        }
    };

    const fetchWorkflow = async () => {
        try {
            const { data, error } = await supabase
                .from('icp_segment_enrichment_workflows')
                .select(`
                    *,
                    icp_segments (
                        icp_name
                    )
                `)
                .eq('canonical_id', canonicalId)
                .eq('tenant_id', activeTenantId)
                .order('version', { ascending: false });
            if (error) throw error;
            setWorkflow(data); // This will now be an array of workflow versions
        } catch (error) {
            console.error('Error fetching workflow:', error);
        } finally {
            setLoading(false);
        }
    };

    const validateFile = (file) => {
        // Check file type
        if (!file.name.toLowerCase().endsWith('.csv')) {
            throw new Error('Only CSV files are allowed');
        }

        // Check file size (1MB = 1048576 bytes)
        if (file.size > 1048576) {
            throw new Error('File size must be less than 1MB');
        }

        return true;
    };

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        setError(null);
        
        if (rejectedFiles && rejectedFiles.length > 0) {
            setError('Only CSV files are allowed');
            return;
        }

        if (acceptedFiles && acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];
            try {
                validateFile(selectedFile);
                setFile(selectedFile);
            } catch (err) {
                setError(err.message);
            }
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv']
        },
        maxFiles: 1,
        multiple: false
    });

    const sanitizeFileName = (fileName) => {
        return fileName
            // Replace non-ASCII characters with underscore
            .replace(/[^\x00-\x7F]/g, '_')
            // Replace whitespace with underscore
            .replace(/\s+/g, '_')
            // Replace multiple consecutive underscores with a single one
            .replace(/_+/g, '_')
            // Remove any remaining special characters
            .replace(/[^a-zA-Z0-9._-]/g, '_');
    };

    const handleUpload = async () => {
        if (!file) return;

        try {
            setError(null);
            const sanitizedName = sanitizeFileName(file.name);
            const fileName = `${Date.now()}-${sanitizedName}`;
            const filePath = `${activeTenantId}/workflow-runs/${fileName}`;

            const { error: uploadError, data } = await supabase.storage
                .from('workflow-files')
                .upload(filePath, file);

            if (uploadError) throw uploadError;

            setUploadedFilePath(filePath);
        } catch (err) {
            setError('Error uploading file: ' + err.message);
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setUploadedFilePath(null);
        setError(null);
    };

    const handleRun = async () => {
        if (!uploadedFilePath || !workflow) return;

        try {
            setIsRunning(true);
            setError(null);

            // Find the workflow version with the highest version number
            const latestWorkflow = workflow.reduce((prev, current) => {
                return (prev.version > current.version) ? prev : current;
            });

            console.log('latestWorkflow', latestWorkflow);

            console.log('uploadedFilePath', uploadedFilePath);

            console.log('activeTenantId', activeTenantId);

            console.log('latestWorkflow.id', latestWorkflow.id);
            
            const response = await fetch(`${API_URL}/api/pipelines/run`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    file_path: uploadedFilePath,
                    tenant_id: activeTenantId,
                    workflow_id: latestWorkflow.id  // Use the ID of the latest workflow version
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to start workflow run');
            }

            const data = await response.json();
            
            // Reset the form state
            setFile(null);
            setUploadedFilePath(null);
            
            // Close the modal
            setOpenUploadDialog(false);
            
            // Refresh the batch processings table
            await fetchBatchProcessings();
            
        } catch (err) {
            setError('Error starting workflow: ' + err.message);
        } finally {
            setIsRunning(false);
        }
    };

    const handleCloseDialog = () => {
        setOpenUploadDialog(false);
        setFile(null);
        setUploadedFilePath(null);
        setError(null);
        setIsRunning(false);
    };

    const handleRowClick = (batchId) => {
        navigate(`/enrichment/workflows/${canonicalId}/batch/${batchId}`);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };

    const getStatusChipColor = (totalRecords) => {
        // You might want to implement logic to determine status color
        return 'primary';
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterChange = (field, value) => {
        setFilters(prev => ({
            ...prev,
            [field]: value
        }));
        setPage(0); // Reset to first page when filters change
    };

    // Add function to generate download URLs
    const getDownloadUrl = (filePath) => {
        if (!filePath) return null;
        const { data: { publicUrl } } = supabase.storage
            .from('workflow-files')
            .getPublicUrl(filePath);
        return publicUrl;
    };

    // Add function to check if batch is complete
    const isBatchComplete = (statusCounts) => {
        const total = statusCounts.total;
        const completed = statusCounts.completed + 
                         statusCounts.completed_stopped + 
                         statusCounts.failed;
        return total === completed;
    };

    const handleDownload = async (batchId, documentType) => {
        try {
            const response = await fetch(`${API_URL}/api/pipelines/batch/download`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    tenant_id: activeTenantId,
                    user_id: session.user.id,
                    batch_id: batchId,
                    document_type: documentType
                })
            });

            if (!response.ok) {
                throw new Error('Download failed');
            }

            // Get the filename from the Content-Disposition header if available
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = `${documentType}-${batchId}.csv`;
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="(.+)"/);
                if (filenameMatch) {
                    filename = filenameMatch[1];
                }
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

        } catch (error) {
            console.error('Error downloading file:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h5">
                    Workflow Runs: {workflow?.name}
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenUploadDialog(true)}
                >
                    Add New File
                </Button>
            </Box>

            {/* Filters */}
            <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="From Date"
                        value={filters.dateFrom}
                        onChange={(newValue) => handleFilterChange('dateFrom', newValue)}
                        slotProps={{ textField: { size: 'small' } }}
                    />
                    <DatePicker
                        label="To Date"
                        value={filters.dateTo}
                        onChange={(newValue) => handleFilterChange('dateTo', newValue)}
                        slotProps={{ textField: { size: 'small' } }}
                    />
                </LocalizationProvider>

                <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>Source Type</InputLabel>
                    <Select
                        value={filters.sourceType}
                        label="Source Type"
                        onChange={(e) => handleFilterChange('sourceType', e.target.value)}
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="csv_file">CSV File</MenuItem>
                        <MenuItem value="api_call">API Call</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Started At</TableCell>
                            <TableCell>Source Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Workflow Version</TableCell>
                            <TableCell>Initiated By</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {batchProcessings.map((batch) => (
                            <TableRow
                                key={batch.id}
                                onClick={() => batch.source_type === 'csv_file' && handleRowClick(batch.id)}
                                sx={batch.source_type === 'csv_file' ? {
                                    cursor: 'pointer',
                                    '&:hover': { backgroundColor: 'action.hover' }
                                } : {}}
                            >
                                <TableCell>
                                    {new Date(batch.created_at).toLocaleString()}
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={batch.source_type === 'csv_file' ? 'CSV File' : 'API Call'}
                                        color={batch.source_type === 'csv_file' ? 'primary' : 'secondary'}
                                        size="small"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Typography variant="body2">
                                                Total: {batch.statusCounts.total}
                                            </Typography>
                                            {isBatchComplete(batch.statusCounts) && (
                                                <Chip label="Completed" color="success" size="small" />
                                            )}
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                            <Typography variant="body2" color="success.main">
                                                Done: {batch.statusCounts.completed + 
                                                      batch.statusCounts.completed_stopped}
                                            </Typography>
                                            {batch.statusCounts.failed > 0 && (
                                                <Typography variant="body2" color="error.main">
                                                    Failed: {batch.statusCounts.failed}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>v{batch.workflowVersion}</TableCell>
                                <TableCell>{batch.users?.email || 'System'}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDownload(batch.id, 'input');
                                            }}
                                        >
                                            Input
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDownload(batch.id, 'output');
                                            }}
                                        >
                                            Result
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={-1} // Use -1 if total count is unknown
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            {/* File Upload Dialog */}
            <Dialog
                open={openUploadDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Upload New File</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    <Box
                        {...getRootProps()}
                        sx={{
                            mt: 2,
                            p: 3,
                            border: '2px dashed',
                            borderColor: isDragActive ? 'primary.main' : 'grey.300',
                            borderRadius: 1,
                            backgroundColor: isDragActive ? 'action.hover' : 'background.paper',
                            cursor: 'pointer',
                            textAlign: 'center'
                        }}
                    >
                        <input {...getInputProps()} />
                        <CloudUploadIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                        <Typography>
                            {isDragActive
                                ? "Drop the CSV file here"
                                : "Drag 'n' drop a CSV file here, or click to select"}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            File must be CSV format and less than 1MB
                        </Typography>
                    </Box>

                    {file && (
                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography>
                                Selected file: {file.name}
                            </Typography>
                            <Button
                                startIcon={<DeleteIcon />}
                                onClick={handleRemoveFile}
                                color="error"
                                size="small"
                            >
                                Remove
                            </Button>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                    {file && !uploadedFilePath && (
                        <Button
                            variant="contained"
                            onClick={handleUpload}
                            disabled={isRunning}
                        >
                            Upload File
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRun}
                        disabled={!uploadedFilePath || isRunning}
                    >
                        {isRunning ? 'Running...' : 'Run Workflow'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default WorkflowRun;