import React from 'react';
import { Paper, Typography, TextField } from '@mui/material';

const ActionNameSection = ({ name, onChange }) => (
    <Paper sx={{ p: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
            Step Name
        </Typography>
        <TextField
            fullWidth
            value={name}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Enter step name"
        />
    </Paper>
);

export default React.memo(ActionNameSection); 