import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import LanguageIcon from '@mui/icons-material/Language';
import PsychologyIcon from '@mui/icons-material/Psychology';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ExtensionIcon from '@mui/icons-material/Extension';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';

const getActionIcon = (actionType) => {
    switch (actionType) {
        case 'scrape':
            return <LanguageIcon />;
        case 'openai':
            return <PsychologyIcon />;
        case 'get_web_tech':
            return <DeveloperBoardIcon />;
        default:
            return <ExtensionIcon />;
    }
};

const DraggableAction = ({ 
    action, 
    index, 
    moveAction, 
    isLast, 
    onActionSelect, 
    selectedActionId, 
    onDeleteAction,
    compact 
}) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'action',
        item: { id: action.id || index, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const [, drop] = useDrop({
        accept: 'action',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveAction(draggedItem.index, index);
                draggedItem.index = index;
            }
        }
    });

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const isSelected = selectedActionId === (action.id || index);
    const ActionIcon = getActionIcon(action.action_type);

    const handleDelete = (e) => {
        e.stopPropagation();
        setShowDeleteDialog(true);
    };

    const confirmDelete = () => {
        onDeleteAction(action);
        setShowDeleteDialog(false);
    };

    const compactBox = (
        <Paper
            elevation={isDragging ? 8 : isSelected ? 6 : 2}
            onClick={() => onActionSelect(action)}
            sx={{
                width: '80px',
                height: '80px',
                backgroundColor: isSelected 
                    ? 'primary.light' 
                    : isDragging 
                        ? 'grey.100' 
                        : 'background.paper',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 1,
                gap: 1,
                transition: 'all 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.05)',
                    elevation: 6,
                    boxShadow: (theme) => theme.shadows[6],
                    cursor: 'pointer'
                },
                zIndex: 1
            }}
        >
            <DragIndicatorIcon color="action" sx={{ fontSize: '1.2rem' }} />
            {ActionIcon}
        </Paper>
    );

    const expandedBox = (
        <Paper
            elevation={isDragging ? 8 : isSelected ? 6 : 2}
            onClick={() => onActionSelect(action)}
            sx={{
                width: '100%',
                backgroundColor: isSelected 
                    ? 'primary.light' 
                    : isDragging 
                        ? 'grey.100' 
                        : 'background.paper',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                p: 2,
                gap: 2,
                transition: 'all 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.02)',
                    elevation: 6,
                    boxShadow: (theme) => theme.shadows[6],
                    cursor: 'pointer'
                },
                zIndex: 1
            }}
        >
            <DragIndicatorIcon color="action" />
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                flex: 1 
            }}>
                {ActionIcon}
                <Typography variant="subtitle2" fontWeight="bold">
                    {action.name}
                </Typography>
            </Box>
            <IconButton 
                size="small" 
                onClick={handleDelete}
                sx={{ 
                    opacity: 0.7,
                    '&:hover': { 
                        opacity: 1,
                        color: 'error.main'
                    }
                }}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Paper>
    );

    return (
        <>
            <Box 
                ref={(node) => drag(drop(node))}
                sx={{ 
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'move',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative'
                }}
            >
                {compact ? (
                    <Tooltip title={`${action.name} (${action.action_type})`} placement="right">
                        {compactBox}
                    </Tooltip>
                ) : (
                    expandedBox
                )}
            </Box>

            <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
                <DialogTitle>Delete Action</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete the action "{action.name}"?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const WorkflowActions = ({ 
    workflow,
    compact = false,
    onActionSelect,
    selectedActionId,
    actions,
    setActions,
    onActionsReorder,
    onAddAction,
    onDeleteAction
}) => {
    const moveAction = (fromIndex, toIndex) => {
        const updatedActions = [...actions];
        const [movedItem] = updatedActions.splice(fromIndex, 1);
        updatedActions.splice(toIndex, 0, movedItem);
        
        const reorderedActions = updatedActions.map((action, index) => ({
            ...action,
            order: index + 1
        }));

        setActions(reorderedActions);
        onActionsReorder?.(reorderedActions);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ 
                    backgroundColor: 'grey.50',
                    borderRadius: '12px',
                    p: 1.5,
                    minHeight: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 0,
                    maxWidth: compact ? '104px' : '600px',
                    margin: '0 auto',
                    position: 'relative'
                }}>
                    {actions.length > 0 ? (
                        <>
                            {!compact && (
                                <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            width: '1px',
                                            height: '12px',
                                            backgroundColor: 'primary.main',
                                            opacity: 0.6
                                        }}
                                    />
                                    <Tooltip title="Add Action" placement="right">
                                        <Fab
                                            size="small"
                                            color="primary"
                                            onClick={() => onAddAction(-1)}
                                            sx={{ 
                                                width: 24,
                                                height: 24,
                                                minHeight: 'unset',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: '0.9rem'
                                                }
                                            }}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Tooltip>
                                    <Box
                                        sx={{
                                            width: '1px',
                                            height: '12px',
                                            backgroundColor: 'primary.main',
                                            opacity: 0.6
                                        }}
                                    />
                                </Box>
                            )}
                            {actions.map((action, index) => (
                                <React.Fragment key={action.id || index}>
                                    <DraggableAction
                                        action={action}
                                        index={index}
                                        moveAction={moveAction}
                                        isLast={index === actions.length - 1}
                                        onActionSelect={onActionSelect}
                                        selectedActionId={selectedActionId}
                                        onDeleteAction={onDeleteAction}
                                        compact={compact}
                                    />
                                    {!compact && (
                                        <Box sx={{ 
                                            position: 'relative', 
                                            width: '100%', 
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            alignItems: 'center',
                                            my: 0
                                        }}>
                                            <Box
                                                sx={{
                                                    width: '1px',
                                                    height: '12px',
                                                    backgroundColor: 'primary.main',
                                                    opacity: 0.6
                                                }}
                                            />
                                            <Tooltip title="Add Action" placement="right">
                                                <Fab
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => onAddAction(index)}
                                                    sx={{ 
                                                        width: 24,
                                                        height: 24,
                                                        minHeight: 'unset',
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: '0.9rem'
                                                        }
                                                    }}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Tooltip>
                                            <Box
                                                sx={{
                                                    width: '1px',
                                                    height: '12px',
                                                    backgroundColor: 'primary.main',
                                                    opacity: 0.6
                                                }}
                                            />
                                        </Box>
                                    )}
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <>
                            <Typography color="text.secondary">
                                No actions defined for this workflow
                            </Typography>
                            {!compact && (
                                <Tooltip title="Add Action" placement="right">
                                    <Fab
                                        size="small"
                                        color="primary"
                                        onClick={() => onAddAction(-1)}
                                        sx={{ 
                                            mt: 2,
                                            width: 24,
                                            height: 24,
                                            minHeight: 'unset',
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '0.9rem'
                                            }
                                        }}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </DndProvider>
    );
};

export default WorkflowActions; 