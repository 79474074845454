import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { supabase } from '../../../supabaseClient';
import { useSnackbar } from '../../../context/SnackbarContext';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Stack,
  TextField,
  Chip
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { formatDistanceToNow } from 'date-fns';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DropzoneArea from './DropzoneArea';

const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes

function MyCustomWorkflowRuns() {
  const { workflowId } = useParams();
  const { activeTenantId, session } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [workflow, setWorkflow] = useState(null);
  const [workflowRuns, setWorkflowRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
  const navigate = useNavigate();


  const fetchWorkflowRuns = async () => {
    try {
      const { data, error } = await supabase
        .from('n8n_workflow_runs')
        .select('*')
        .eq('n8n_workflow_id', workflowId)
        .eq('tenant_id', activeTenantId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setWorkflowRuns(data);
    } catch (err) {
      console.error('Error fetching workflow runs:', err);
      showSnackbar('Failed to fetch workflow runs', 'error');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch workflow details
        const { data: workflowData, error: workflowError } = await supabase
          .from('n8n_workflows')
          .select('*')
          .eq('id', workflowId)
          .eq('tenant_id', activeTenantId)
          .single();

        if (workflowError) throw workflowError;
        setWorkflow(workflowData);

        // Fetch workflow runs
        await fetchWorkflowRuns();
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (activeTenantId && workflowId) {
      fetchData();
    }
  }, [activeTenantId, workflowId]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadError(null);

    if (!file) return;

    // Validate file type
    if (file.type !== 'text/csv') {
      setUploadError('Only CSV files are allowed');
      return;
    }

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      setUploadError('File size must be less than 1MB');
      return;
    }

    setUploadFile(file);
  };

  const handleUpload = async () => {
    if (!uploadFile) return;

    try {
      // Create FormData object
      const formData = new FormData();
      formData.append('file', uploadFile);
      formData.append('tenantId', activeTenantId);
      formData.append('workflowId', workflowId);

      // Call backend API
      const response = await fetch(`${API_URL}/api/custom-workflows/run-workflow`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to start workflow');
      }

      const data = await response.json();
      
      showSnackbar('Workflow started successfully', 'success');
      setIsModalOpen(false);
      setUploadFile(null);
      fetchWorkflowRuns(); // Refresh the runs list
    } catch (err) {
      console.error('Error starting workflow:', err);
      showSnackbar(err.message || 'Failed to start workflow', 'error');
    }
  };

  const handleRowClick = (runId) => {
    navigate(`/my-custom-workflows/${workflowId}/${runId}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Alert severity="error">Error: {error}</Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">
          {workflow?.meta?.name || `Workflow ${workflow?.id}`}
        </Typography>
        <Button
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          Launch New Run
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Run ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Started</TableCell>
              <TableCell>Finished</TableCell>
              <TableCell>Input File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workflowRuns.map((run) => (
              <TableRow 
                key={run.id}
                onClick={() => handleRowClick(run.id)}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': { bgcolor: 'action.hover' }
                }}
              >
                <TableCell>{run.id}</TableCell>
                <TableCell>
                  <Chip
                    label={run.status}
                    color={
                      run.status === 'completed' ? 'success' :
                      run.status === 'failed' ? 'error' :
                      'default'
                    }
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {formatDistanceToNow(new Date(run.created_at))} ago
                </TableCell>
                <TableCell>
                  {run.finished_at ? formatDistanceToNow(new Date(run.finished_at)) + ' ago' : '-'}
                </TableCell>
                <TableCell>
                  {run.meta?.input_file?.name || '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="upload-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1
        }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="upload-modal-title" variant="h6">
              Launch New Workflow Run
            </Typography>
            <IconButton onClick={() => setIsModalOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <DropzoneArea
            uploadFile={uploadFile}
            uploadError={uploadError}
            onFileChange={setUploadFile}
            onErrorChange={setUploadError}
          />

          <Box mt={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleUpload}
              disabled={!uploadFile || !!uploadError}
              startIcon={<FileUploadIcon />}
            >
              Upload and Start
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default MyCustomWorkflowRuns; 