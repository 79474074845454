import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { supabase } from '../../../supabaseClient';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

function MyCustomWorkflowRunDetails() {
  const { workflowId, runId } = useParams();
  const { activeTenantId } = useAuth();
  const [workflowRun, setWorkflowRun] = useState(null);
  const [runDetails, setRunDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch workflow run
        const { data: runData, error: runError } = await supabase
          .from('n8n_workflow_runs')
          .select('*')
          .eq('id', runId)
          .eq('tenant_id', activeTenantId)
          .single();

        if (runError) throw runError;
        setWorkflowRun(runData);

        // Fetch run details
        const { data: detailsData, error: detailsError } = await supabase
          .from('n8n_workflow_runs_details')
          .select('*')
          .eq('n8n_workflow_run_id', runId)
          .eq('tenant_id', activeTenantId)
          .order('created_at', { ascending: true });

        if (detailsError) throw detailsError;
        setRunDetails(detailsData);
        console.log('runDetails', runDetails);

      } catch (err) {
        console.error('Error fetching run details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (activeTenantId && runId) {
      fetchData();
    }
  }, [activeTenantId, runId]);

  // Function to get all unique keys from meta objects
  const getMetaColumns = () => {
    const columns = new Set();
    runDetails.forEach(detail => {
      if (detail.meta && typeof detail.meta === 'object') {
        Object.keys(detail.meta).forEach(key => columns.add(key));
      }
    });
    return Array.from(columns);
  };

  // Function to format meta value based on its type
  const formatMetaValue = (value) => {
    if (value === null || value === undefined) return '-';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'number') return value.toString();
    if (typeof value === 'string') return value.trim();
    if (typeof value === 'object') return JSON.stringify(value);
    return value;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Alert severity="error">Error: {error}</Alert>
      </Box>
    );
  }

  return (
    <Box>
      {/* Run Overview */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" color="text.secondary">Run ID</Typography>
              <Typography variant="body1">{workflowRun.id}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" color="text.secondary">Status</Typography>
              <Chip
                label={workflowRun.status}
                color={
                  workflowRun.status === 'completed' ? 'success' :
                  workflowRun.status === 'failed' ? 'error' :
                  'default'
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" color="text.secondary">Started</Typography>
              <Typography variant="body1">
                {formatDistanceToNow(new Date(workflowRun.created_at))} ago
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" color="text.secondary">Input File</Typography>
              <Typography variant="body1">
                {workflowRun.meta?.input_file?.name || '-'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Updated Run Details Table */}
      <Typography variant="h6" gutterBottom>
        Processing Details
      </Typography>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>Status</TableCell>
              {getMetaColumns().map(column => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {runDetails.map((detail) => (
              <TableRow key={detail.id}>
                <TableCell>
                  {formatDistanceToNow(new Date(detail.created_at))} ago
                </TableCell>
                <TableCell>
                  <Chip
                    label={detail.status}
                    color={
                      detail.status === 'completed' ? 'success' :
                      detail.status === 'failed' ? 'error' :
                      'default'
                    }
                    size="small"
                  />
                </TableCell>
                {getMetaColumns().map(column => (
                  <TableCell key={column}>
                    {formatMetaValue(detail.meta?.[column])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add a no data message if needed */}
      {runDetails.length === 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          No processing details available yet
        </Alert>
      )}
    </Box>
  );
}

export default MyCustomWorkflowRunDetails; 