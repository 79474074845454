import React from 'react';
import { Routes, Route, Navigate, useLocation, Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Material UI Core
import { 
    Box, 
    Typography, 
    IconButton, 
    Avatar, 
    Stack, 
    Container, 
    List, 
    ListItem, 
    Select, 
    MenuItem as MuiMenuItem,
    Popover,
    Card,
    CardContent,
    Button,
    Divider,
    Grid,
    Tooltip
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// Material UI Icons
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import WorkspacesIcon from '@mui/icons-material/WorkspacesOutlined';
import SettingsIcon from '@mui/icons-material/Settings';

// Context and Services
import { supabase } from '../supabaseClient';
import { useSnackbar } from '../context/SnackbarContext';
import { useAuth } from '../context/AuthContext';
import PageHeader from '../context/PageHeader';
import { getBreadcrumbItems } from '../config/routes';

// DnD
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Layout Components
import SidebarMenu from './layout/SidebarMenu';

// Report Components
import Reports from './reports/reports';
import CohortMQLS from './reports/cohort/mqls';
import Funnels from './reports/funnels/funnels';
import FunnelForm from './reports/funnels/funnel-form';
import Conversions from './reports/conversions/conversions';
import Cashflow from './reports/cashflow/cashflow';

// Business Model Components
import BusinessHome from './business-model/business-home';
import Configure from './business-model/Configure';
import Campaigns from './business-model/Campaigns';
import Business from './business-model/Business';
import Product from './business-model/Product';
import ICPSegmentForm from './business-model/ICPSegmentForm';

// Workflow Components
import CustomWorkflows from './custom-workflows/admin/custom-workflows';
import WorkflowDetails from './custom-workflows/admin/workflow-details';
import MyCustomWorkflows from './custom-workflows/enduser/my-custom-workflows';
import MyCustomWorkflowRuns from './custom-workflows/enduser/my-custom-workflow-runs';
import MyCustomWorkflowRunDetails from './custom-workflows/enduser/my-custom-workflow-run-details';

// Data & Enrichment Components
import DataEnrichment from './enrichment/Enrichment';
import WorkflowRun from './enrichment_run/WorkflowRun';
import BatchDetails from './enrichment_run/BatchDetails';
import WorkflowConfiguration from './enrichment/WorkflowConfiguration';

// User & Auth Components
import UserSettings from './user/user_settings';
import WorkspaceSettings from './workspace/workspace_settings';
import ResetPassword from './auth/reset-password';

const LayoutWrapper = styled(Box)(({ theme }) => ({
    ...theme.components.MuiLayout.styleOverrides.root
}));

const Sidebar = styled(Box)(({ theme }) => ({
    ...theme.components.MuiLayout.styleOverrides.sidebar
}));

const Content = styled('main')(({ theme }) => ({
    flexGrow: 1,
    marginLeft: 280,
    transition: 'margin-left 0.3s ease',
    padding: theme.spacing(3),
    overflow: 'hidden',
    width: `calc(100% - 280px)`,
    '&.collapsed': {
        marginLeft: 70,
        width: `calc(100% - 70px)`
    }
}));

const MenuArea = styled(Box)(({ theme }) => ({
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
}));

const SidebarFooter = styled(Box)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: 'auto',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '.collapsed &': {
        padding: theme.spacing(2, 1),
        alignItems: 'center'
    }
}));

const SidebarMenuItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    transition: 'all 0.2s ease',

    '.collapsed &': {
        padding: theme.spacing(1.5, 0),
        justifyContent: 'center',
        marginLeft: 0,
        marginRight: 0
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.primary.main
    },

    '&.active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.selected,
        fontWeight: 500,

        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main
        }
    }
}));

const MenuText = styled('span')(({ theme }) => ({
    whiteSpace: 'nowrap',
    opacity: 1,
    transition: 'opacity 0.2s ease',
    '.collapsed &': {
        opacity: 0,
        width: 0,
        display: 'none'
    }
}));

const MenuIconWrapper = styled(Box)(({ theme }) => ({
    minWidth: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit'
}));

const LogoutIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.secondary,
    flexShrink: 0,
    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.hover
    }
}));

const Layout = () => {
    const location = useLocation();
    const theme = useTheme();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    
    // Get all necessary data from AuthContext
    const { 
        permissions,
        tenantType,
        activeTenantId,
        activeTenantName,
        workspaces,
        session,
        refreshToken
    } = useAuth();

    // Transform workspaces data for display
    const formattedWorkspaces = workspaces?.map(workspace => ({
        id: workspace.tenant_id,
        name: workspace.name,
        role: workspace.role,
        isDefault: false
    })) || [];

    // Update user state to use session data
    const [userAvatar, setUserAvatar] = useState(session?.user?.user_metadata?.avatar_url);
    const [userName, setUserName] = useState(session?.user?.user_metadata?.name);

    const handleTenantSwitch = async (tenantId) => {
        try {
            const { error } = await supabase
                .rpc('switch_active_tenant', {
                    p_user_id: session.user.id,
                    p_tenant_id: tenantId
                });

            if (error) throw error;

            // Refresh the token to get updated claims
            await refreshToken();

            handleWorkspaceSwitcherClose();
        } catch (error) {
            console.error('Error switching tenant:', error);
            showSnackbar('Failed to switch workspace', 'error');
        }
    };

    const handleAvatarClick = () => {
        navigate('/user/settings');
    };

    // Handle workspace switcher open/close
    const handleWorkspaceSwitcherClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleWorkspaceSwitcherClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // Add handler for workspace settings
    const handleWorkspaceSettings = () => {
        navigate('/workspace');
        handleWorkspaceSwitcherClose();
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <LayoutWrapper>
                <Sidebar className={isCollapsed ? 'collapsed' : ''}>
                    {/* Logo section */}
                    <Box sx={{
                        p: 3,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        display: 'flex',
                        alignItems: 'center',
                        '.collapsed &': {
                            padding: theme.spacing(2, 1),
                            justifyContent: 'center'
                        }
                    }}>
                        <img
                            src="/images/logo-dark.png"
                            alt="KevLog Logo"
                            style={{
                                height: '32px',
                                width: 'auto',
                                display: isCollapsed ? 'none' : 'block',
                                marginRight: 'auto'
                            }}
                        />
                        <IconButton
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            size="small"
                            sx={{
                                width: 40,
                                height: 40,
                                ml: isCollapsed ? 0 : 'auto',
                                '.collapsed &': {
                                    margin: 0
                                }
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>

                    <MenuArea>
                        <SidebarMenu />
                    </MenuArea>

                    {/* Footer */}
                    <SidebarFooter>
                        {/* Workspace Switcher Section */}
                        <Box
                            onClick={handleWorkspaceSwitcherClick}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1.5,
                                cursor: 'pointer',
                                py: 1,
                                pl: 0,
                                pr: 1,
                                width: '100%',
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                '.collapsed &': {
                                    padding: 0,
                                    width: 40,
                                    justifyContent: 'center'
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 40,
                                    height: 40,
                                    borderRadius: '50%',
                                    bgcolor: theme.palette.primary.main,
                                    flexShrink: 0,
                                    color: theme.palette.primary.contrastText
                                }}
                            >
                                <WorkspacesIcon sx={{ fontSize: 24 }} />
                            </Box>

                            {!isCollapsed && (
                                <Box className="workspace-info">
                                    <Typography variant="body2" fontWeight={500}>
                                        {activeTenantName || 'Select Workspace'}
                                    </Typography>
                                    <Typography variant="caption">
                                        Switch workspace
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        {/* Workspace Switcher Popover */}
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleWorkspaceSwitcherClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                sx: { width: 320, maxHeight: 480 }
                            }}
                        >
                            <Card elevation={0}>
                                <CardContent>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 2
                                    }}>
                                        <Typography variant="h6">
                                            Workspaces
                                        </Typography>
                                        {activeTenantId && (
                                            <Tooltip title="Workspace Settings">
                                                <IconButton
                                                    size="small"
                                                    onClick={handleWorkspaceSettings}
                                                >
                                                    <SettingsIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                    <Grid container spacing={1}>
                                        {formattedWorkspaces.map(workspace => (
                                            <Grid item xs={12} key={workspace.id}>
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        cursor: 'pointer',
                                                        backgroundColor:
                                                            workspace.id === activeTenantId
                                                                ? theme.palette.primary.light
                                                                : 'inherit',
                                                        '&:hover': {
                                                            borderColor: theme.palette.primary.main
                                                        }
                                                    }}
                                                    onClick={() => handleTenantSwitch(workspace.id)}
                                                >
                                                    <CardContent>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                            <WorkspacesIcon color={workspace.id === activeTenantId ? 'primary' : 'inherit'} />
                                                            <Box sx={{ flex: 1 }}>
                                                                <Typography variant="body2" fontWeight={500}>
                                                                    {workspace.name}
                                                                </Typography>
                                                                <Typography variant="caption" color="text.secondary">
                                                                    {workspace.role || 'Member'}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Popover>

                        {/* User Section */}
                        <Box
                            onClick={handleAvatarClick}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1.5,
                                cursor: 'pointer',
                                py: 1,
                                pl: 0,
                                pr: 1,
                                width: '100%',
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                '.collapsed &': {
                                    padding: 0,
                                    width: 40,
                                    justifyContent: 'center'
                                }
                            }}
                        >
                            <Avatar
                                src={userAvatar}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: theme.palette.primary.main,
                                    flexShrink: 0
                                }}
                            >
                                {userName?.charAt(0) || session?.user?.email?.charAt(0).toUpperCase()}
                            </Avatar>

                            {!isCollapsed && (
                                <>
                                    <Box
                                        className="user-info"
                                        sx={{
                                            flex: 1,
                                            minWidth: 0,
                                            transition: 'color 0.2s ease-in-out',
                                        }}
                                    >
                                        <Typography variant="body2">
                                            {session?.user?.email || 'Guest'}
                                        </Typography>
                                        <Typography variant="caption">
                                            {activeTenantName}
                                        </Typography>
                                    </Box>

                                    <LogoutIconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            supabase.auth.signOut();
                                        }}
                                        size="small"
                                    >
                                        <LogoutIcon fontSize="small" />
                                    </LogoutIconButton>
                                </>
                            )}
                        </Box>
                    </SidebarFooter>
                </Sidebar>

                {/* Page Content */}
                <Content
                    flex={1}
                    className={isCollapsed ? 'collapsed' : ''}
                    id="page-content-wrapper"
                >
                    <Box>
                        <PageHeader items={getBreadcrumbItems(location.pathname)} />

                        <Routes>
                            <Route path="/" element={<Typography variant="h1">Welcome to Collextr tools</Typography>} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/reports/lifecycle-cohort" element={<CohortMQLS />} />
                            <Route path="/reports/conversions" element={<Conversions />} />
                            <Route path="/reports/funnels" element={<Funnels />} />
                            <Route path="/workspace/" element={<WorkspaceSettings />} />
                            <Route path="/reports/funnels/new" element={<FunnelForm />} />
                            <Route path="/reports/funnels/:id/edit" element={<FunnelForm />} />
                            <Route path="/user/settings" element={<UserSettings />} />
                            <Route path="/user/reset-password" element={<ResetPassword />} />
                            <Route path="/workspace/settings" element={<WorkspaceSettings />} />
                            <Route path="/business" element={<BusinessHome />} />
                            <Route path="/enrichment" element={<DataEnrichment />} />
                            <Route path="/custom-workflows" element={<CustomWorkflows />} />
                            <Route path="/custom-workflows/:workflowId" element={<WorkflowDetails />} />
                            <Route path="/business/configure" element={<Configure />} />
                            <Route path="/business/campaigns" element={<Campaigns />} />
                            <Route path="/business/configure/business/:id" element={<Business />} />
                            <Route path="/business/configure/business/new" element={<Business />} />
                            <Route path="/business/configure/business/:businessId/product/:productId" element={<Product />} />
                            <Route path="/business/configure/business/:businessId/product/new" element={<Product />} />
                            <Route path="/business/configure/business/:businessId/product/:productId/segments/new" element={<ICPSegmentForm />} />
                            <Route path="/business/configure/business/:businessId/product/:productId/segments/:segmentId/edit" element={<ICPSegmentForm />} />
                            <Route path="/enrichment/workflows/:id/run" element={<WorkflowRun />} />
                            <Route path="/enrichment/workflows/:id/batch/:batchId" element={<BatchDetails />} />
                            <Route path="/enrichment/workflows/:id/configure" element={<WorkflowConfiguration />} />
                            <Route path="/my-custom-workflows" element={<MyCustomWorkflows />} />
                            <Route path="/my-custom-workflows/:workflowId" element={<MyCustomWorkflowRuns />} />
                            <Route path="/my-custom-workflows/:workflowId/:runId" element={<MyCustomWorkflowRunDetails />} />
                            <Route path="/reports/cashflow" element={<Cashflow />} />

                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </Box>
                </Content>
            </LayoutWrapper>
        </DndProvider>
    );
}

export default Layout;