import React, { memo, useCallback } from 'react';
import { 
    Box, 
    Typography, 
    TextField,
    Paper,
    FormControl,
    Select,
    MenuItem,
    Button,
    IconButton,
    Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Constants
const OPERATORS = {
    "eq": "equals",
    "neq": "not equals",
    "gt": "greater than",
    "gte": "greater than or equals",
    "lt": "less than",
    "lte": "less than or equals",
    "contains": "contains",
    "not_contains": "does not contain",
    "is_null": "is null",
    "is_not_null": "is not null",
    "starts_with": "starts with",
    "not_starts_with": "does not start with"
};

const LOGICAL_OPERATORS = ["and", "or"];

// Single Condition Component
const Condition = memo(({ condition, onUpdate, onDelete, availableFields }) => {
    console.log('Condition availableFields:', availableFields);
    const needsValue = !['is_null', 'is_not_null'].includes(condition.operator);

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', width: '100%' }}>
            <FormControl size="small" sx={{ minWidth: 200 }}>
                <Select
                    value={condition.field || ''}
                    onChange={(e) => onUpdate({ 
                        ...condition, 
                        field: e.target.value 
                    })}
                    displayEmpty
                    disabled={!availableFields?.length}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300
                            }
                        }
                    }}
                >
                    <MenuItem value="" disabled><em>Select field</em></MenuItem>
                    {availableFields.map((field) => (
                        <MenuItem 
                            key={`${field.source}-${field.name}`} 
                            value={field.name}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body2">
                                    {field.name} ({field.type})
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                    {field.source}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Select
                size="small"
                value={condition.operator || 'eq'}
                onChange={(e) => {
                    const newOperator = e.target.value;
                    const needsValue = !['is_null', 'is_not_null'].includes(newOperator);
                    onUpdate({ 
                        ...condition, 
                        operator: newOperator,
                        ...(needsValue ? {} : { value: undefined })
                    });
                }}
                sx={{ minWidth: 150 }}
            >
                {Object.entries(OPERATORS).map(([key, label]) => (
                    <MenuItem key={key} value={key}>{label}</MenuItem>
                ))}
            </Select>

            {needsValue && (
                <TextField
                    size="small"
                    value={condition.value || ''}
                    onChange={(e) => onUpdate({ 
                        ...condition, 
                        value: e.target.value 
                    })}
                    placeholder="Value"
                    sx={{ minWidth: 150 }}
                />
            )}

            <IconButton size="small" onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </Box>
    );
});

// FilterGroup Component
const FilterGroup = memo(({ node, onUpdate, onDelete, level = 0, availableFields }) => {

    // If no node is provided, return early
    if (!node) {
        console.warn('FilterGroup received null/undefined node');
        return null;
    }

    return (
        <Paper 
            sx={{ 
                p: 2, 
                ml: level * 2,
                border: '1px solid #e0e0e0',
                backgroundColor: level % 2 === 0 ? 'background.paper' : 'grey.50'
            }}
        >
            <Stack spacing={2}>
                {/* Logical operator selector */}
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Select
                        size="small"
                        value={node.operator || 'and'}
                        onChange={(e) => onUpdate({ ...node, operator: e.target.value })}
                        sx={{ minWidth: 100 }}
                    >
                        {LOGICAL_OPERATORS.map((key) => (
                            <MenuItem key={key} value={key}>
                                {key}
                            </MenuItem>
                        ))}
                    </Select>
                    {level > 0 && (
                        <IconButton size="small" onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Box>

                {/* Render existing conditions */}
                {node.conditions && node.conditions.map((condition, index) => (
                    <Box key={index}>
                        {condition.operator && condition.conditions ? (
                            // Nested group
                            <FilterGroup
                                node={condition}
                                onUpdate={(updatedCondition) => {
                                    const newConditions = [...node.conditions];
                                    newConditions[index] = updatedCondition;
                                    onUpdate({ ...node, conditions: newConditions });
                                }}
                                onDelete={() => {
                                    const newConditions = node.conditions.filter((_, i) => i !== index);
                                    onUpdate({ ...node, conditions: newConditions });
                                }}
                                level={level + 1}
                                availableFields={availableFields}
                            />
                        ) : (
                            // Single condition
                            <Condition
                                condition={condition}
                                onUpdate={(updatedCondition) => {
                                    const newConditions = [...node.conditions];
                                    newConditions[index] = updatedCondition;
                                    onUpdate({ ...node, conditions: newConditions });
                                }}
                                onDelete={() => {
                                    const newConditions = node.conditions.filter((_, i) => i !== index);
                                    onUpdate({ ...node, conditions: newConditions });
                                }}
                                availableFields={availableFields}
                            />
                        )}
                    </Box>
                ))}

                {/* Action buttons */}
                <Stack direction="row" spacing={1}>
                    <Button
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            const newConditions = [...(node.conditions || []), { 
                                field: '', 
                                operator: 'eq', 
                                value: '' 
                            }];
                            onUpdate({ ...node, conditions: newConditions });
                        }}
                    >
                        Add Condition
                    </Button>
                    <Button
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            const newConditions = [...(node.conditions || []), {
                                operator: 'and',
                                conditions: []
                            }];
                            onUpdate({ ...node, conditions: newConditions });
                        }}
                    >
                        Add Group
                    </Button>
                </Stack>
            </Stack>
        </Paper>
    );
});

// Main Filter Component
const Filter = ({ filter, onFilterChange, availableFields }) => {

    // Ensure we always have a valid filter structure
    const safeFilter = filter || {
        operator: 'and',
        conditions: []
    };

    return (
        <FilterGroup
            node={safeFilter}
            onUpdate={onFilterChange}
            availableFields={availableFields}
            level={0}
        />
    );
};

export default Filter; 