import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Paper,
    Button,
    Stack,
    Grid,
    Card,
    CardContent,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../context/AuthContext';

const ParameterValues = ({ parameterId }) => {
    const { activeTenantId } = useAuth();
    const [values, setValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editingValue, setEditingValue] = useState(null);
    const [formData, setFormData] = useState({
        value: '',
        description: '',
        tenant_id: activeTenantId
    });

    useEffect(() => {
        fetchValues();
    }, [parameterId]);

    const fetchValues = async () => {
        try {
            const { data, error } = await supabase
                .from('dimension_values')
                .select('*')
                .eq('dimension_id', parameterId)
                .order('created_at', { ascending: true });

            if (error) throw error;
            setValues(data || []);
        } catch (error) {
            console.error('Error fetching values:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (value = null) => {
        if (value) {
            setEditingValue(value);
            setFormData({
                value: value.value,
                description: value.description || ''
            });
        } else {
            setEditingValue(null);
            setFormData({
                value: '',
                description: ''
            });
        }
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setEditingValue(null);
        setFormData({
            value: '',
            description: ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingValue) {
                const { error } = await supabase
                    .from('dimension_values')
                    .update({
                        value: formData.value,
                        description: formData.description
                    })
                    .eq('id', editingValue.id);
                if (error) throw error;
            } else {
                const { error } = await supabase
                    .from('dimension_values')
                    .insert([{
                        dimension_id: parameterId,
                        value: formData.value,
                        description: formData.description,
                        tenant_id: activeTenantId
                    }]);
                if (error) throw error;
            }
            handleCloseDialog();
            fetchValues();
        } catch (error) {
            console.error('Error saving value:', error);
        }
    };

    const handleDelete = async (valueId) => {
        if (window.confirm('Are you sure you want to delete this value?')) {
            try {
                const { error } = await supabase
                    .from('dimension_values')
                    .delete()
                    .eq('id', valueId);
                if (error) throw error;
                fetchValues();
            } catch (error) {
                console.error('Error deleting value:', error);
            }
        }
    };

    if (loading) {
        return <Box sx={{ p: 3 }}>Loading...</Box>;
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">Values</Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenDialog()}
                >
                    Add Value
                </Button>
            </Box>

            <Grid container spacing={3}>
                {values.length === 0 ? (
                    <Grid item xs={12}>
                        <Paper 
                            sx={{ 
                                p: 3, 
                                textAlign: 'center',
                                backgroundColor: 'grey.100'
                            }}
                        >
                            <Typography variant="body1" color="textSecondary">
                                No values defined. Click the button above to add your first value.
                            </Typography>
                        </Paper>
                    </Grid>
                ) : (
                    values.map((value) => (
                        <Grid item xs={12} sm={6} md={4} key={value.id}>
                            <Card>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                        <Typography variant="h6" component="h2">
                                            {value.value}
                                        </Typography>
                                        <Box>
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleOpenDialog(value)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton 
                                                size="small" 
                                                onClick={() => handleDelete(value.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {value.description && (
                                        <Typography variant="body2" color="textSecondary">
                                            {value.description}
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                )}
            </Grid>

            {/* Value Dialog */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {editingValue ? 'Edit Value' : 'New Value'}
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Stack spacing={3}>
                            <TextField
                                required
                                fullWidth
                                label="Value"
                                name="value"
                                value={formData.value}
                                onChange={(e) => setFormData(prev => ({ ...prev, value: e.target.value }))}
                            />
                            <TextField
                                fullWidth
                                label="Description"
                                name="description"
                                value={formData.description}
                                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                                multiline
                                rows={3}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button type="submit" variant="contained">
                            {editingValue ? 'Save' : 'Create'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
};

export default ParameterValues; 