import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../../supabaseClient';
import { useAuth } from '../../../context/AuthContext';
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';

import BasicInfo from './funnel-form/BasicInfo';
import DataSourceConfig from './funnel-form/DataSourceConfig';
import DataColumns from './funnel-form/DataColumns';
import StagesConfig from './funnel-form/StagesConfig';

const FunnelForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { session, refreshToken, isTokenExpired, activeTenantId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [hubspotProperties, setHubspotProperties] = useState([]);
  const [hubspotDateProperties, setHubspotDateProperties] = useState([]);
  const [loadingProperties, setLoadingProperties] = useState(false);
  
  const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

  const [funnel, setFunnel] = useState({
    name: '',
    description: '',
    data: {
      description: '',
      stages: []
    },
    data_source: {
      source: {
        type: '',
        value: '',
        name: ''
      },
      data: []
    }
  });

  useEffect(() => {
    if (id && activeTenantId) {
      fetchFunnel();
    }
  }, [id, activeTenantId]);

  useEffect(() => {
    fetchHubspotProperties();
  }, []);

  const fetchFunnel = async () => {
    setLoading(true);
    try {
      if (!activeTenantId) {
        throw new Error('No tenant ID found');
      }

      const { data, error } = await supabase
        .from('funnels')
        .select('*')
        .eq('id', id)
        .eq('tenant_id', activeTenantId)
        .single();

      if (error) throw error;
      
      const funnelData = {
        ...data,
        data_source: data.data_source || {
          source: {
            type: '',
            value: '',
            name: ''
          },
          data: []
        }
      };
      
      setFunnel(funnelData);
    } catch (error) {
      console.error('Error fetching funnel:', error);
      navigate('/funnels', { 
        state: { 
          error: error.message || 'Error loading funnel' 
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchHubspotProperties = async () => {
    setLoadingProperties(true);
    try {
      if (!activeTenantId) {
        throw new Error('No tenant ID found');
      }

      if (isTokenExpired(session)) {
        await refreshToken();
      }

      const response = await fetch(`${API_URL}/api/hubspot/properties`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch HubSpot properties');
      }

      const data = await response.json();
      const properties = data.results || [];

      const enumProperties = properties.filter(prop => 
        prop.type === 'enumeration' || 
        (prop.fieldType === 'select' && prop.options && prop.options.length > 0)
      );
      
      const dateProperties = properties.filter(prop => 
        prop.type === 'date' || 
        prop.type === 'datetime' ||
        prop.fieldType === 'date' ||
        prop.fieldType === 'datetime'
      );
      
      setHubspotProperties(enumProperties);
      setHubspotDateProperties(dateProperties);
    } catch (error) {
      console.error('Error fetching HubSpot properties:', error);
      setHubspotProperties([]);
      setHubspotDateProperties([]);
    } finally {
      setLoadingProperties(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log('Active Tenant ID:', activeTenantId);

    try {
      if (!activeTenantId) {
        throw new Error('No tenant ID found');
      }

      const funnelData = {
        name: funnel.name,
        description: funnel.description,
        data: funnel.data,
        data_source: funnel.data_source,
        tenant_id: activeTenantId
      };

      if (id) {
        const { error } = await supabase
          .from('funnels')
          .update(funnelData)
          .eq('id', id)
          .eq('tenant_id', activeTenantId);

        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('funnels')
          .insert([funnelData]);

        if (error) throw error;
      }

      navigate('/funnels');
    } catch (error) {
      console.error('Error saving funnel:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'description') {
      setFunnel(prev => ({
        ...prev,
        data: {
          ...prev.data,
          description: value
        }
      }));
    } else {
      setFunnel(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDataSourceChange = (field, value) => {
    setFunnel(prev => ({
      ...prev,
      data_source: {
        ...prev.data_source,
        source: {
          ...prev.data_source.source,
          [field]: value
        }
      }
    }));
  };

  const handleDataColumnChange = (index, field, value) => {
    setFunnel(prev => ({
      ...prev,
      data_source: {
        ...prev.data_source,
        data: prev.data_source.data.map((item, i) => 
          i === index ? { ...item, [field]: value } : item
        )
      }
    }));
  };

  const addDataColumn = () => {
    setFunnel(prev => ({
      ...prev,
      data_source: {
        ...prev.data_source,
        data: [
          ...prev.data_source.data,
          {
            object: '',
            property_name: '',
            property_label: '',
            data_type: ''
          }
        ]
      }
    }));
  };

  const removeDataColumn = (index) => {
    setFunnel(prev => ({
      ...prev,
      data_source: {
        ...prev.data_source,
        data: prev.data_source.data.filter((_, i) => i !== index)
      }
    }));
  };

  const handleStageChange = (index, field, value) => {
    setFunnel(prev => ({
      ...prev,
      data: {
        ...prev.data,
        stages: prev.data.stages.map((stage, i) => 
          i === index ? { ...stage, [field]: value } : stage
        )
      }
    }));
  };

  const addStage = () => {
    setFunnel(prev => ({
      ...prev,
      data: {
        ...prev.data,
        stages: [
          ...prev.data.stages,
          {
            name: '',
            order: prev.data.stages.length + 1,
            type: 'string',
            hs_property_enum_value: '',
            label_name: '',
            date_property: '',
            label_date: ''
          }
        ]
      }
    }));
  };

  const removeStage = (index) => {
    setFunnel(prev => ({
      ...prev,
      data: {
        ...prev.data,
        stages: prev.data.stages.filter((_, i) => i !== index)
      }
    }));
  };

  if (loading && id) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {id ? 'Edit Funnel' : 'Create New Funnel'}
        </Typography>

        <form onSubmit={handleSubmit}>
          <BasicInfo 
            funnel={funnel} 
            handleChange={handleChange} 
            API_URL={API_URL}
          />

          <DataSourceConfig 
            funnel={funnel}
            handleDataSourceChange={handleDataSourceChange}
            API_URL={API_URL}
            activeTenantId={activeTenantId}
          />

          <DataColumns 
            dataColumns={funnel.data_source.data}
            handleDataColumnChange={handleDataColumnChange}
            addDataColumn={addDataColumn}
            removeDataColumn={removeDataColumn}
            API_URL={API_URL}
          />

          <StagesConfig 
            stages={funnel.data.stages}
            hubspotProperties={hubspotProperties}
            hubspotDateProperties={hubspotDateProperties}
            loadingProperties={loadingProperties}
            handleStageChange={handleStageChange}
            addStage={addStage}
            removeStage={removeStage}
          />

          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : (id ? 'Update' : 'Create')}
            </Button>

            <Button
              variant="outlined"
              onClick={() => navigate('/reports/funnels')}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default FunnelForm;