import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [tenantType, setTenantType] = useState(null);
  const [activeTenantId, setActiveTenantId] = useState(null);
  const [activeTenantName, setActiveTenantName] = useState(null);


  const processSession = async (currentSession) => {
    if (!currentSession) {
      return null;
    }
    
    try {
      const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
      
      // Call backend to decode token
      const response = await fetch(`${API_URL}/api/users/auth`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${currentSession.access_token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to decode token');
      }

      // Destructure the exact response structure
      const { active_tenant_id, permissions, tenant_type, workspaces, active_tenant_name } = await response.json();
      
      setPermissions(permissions || []);
      setTenantType(tenant_type);
      setActiveTenantId(active_tenant_id);
      setWorkspaces(workspaces || []);
      setActiveTenantName(active_tenant_name);


      return currentSession;
    } catch (error) {
      console.error('Error processing session:', error);
      return null;
    }
  };

  const refreshToken = async () => {
    try {
      const { data: { session: newSession }, error } = await supabase.auth.refreshSession();
      if (error) throw error;
      
      await processSession(newSession);
      setSession(newSession);
      return newSession;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  };

  const isTokenExpired = (session) => {
    if (!session?.expires_at) return true;
    
    const expiresAt = session.expires_at * 1000;
    const now = Date.now();
    const fiveMinutes = 5 * 60 * 1000;
    
    return now + fiveMinutes >= expiresAt;
  };

  useEffect(() => {
    const getSession = async () => {
      try {
        const { data: { session: currentSession } } = await supabase.auth.getSession();
        
        if (currentSession && isTokenExpired(currentSession)) {
          const newSession = await refreshToken();
          setSession(newSession);
        } else {
          await processSession(currentSession);
          setSession(currentSession);
        }
      } catch (error) {
        console.error('Error getting session:', error);
      } finally {
        setLoading(false);
      }
    };

    getSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {
      if (session && isTokenExpired(session)) {
        const newSession = await refreshToken();
        setSession(newSession);
      } else {
        await processSession(session);
        setSession(session);
      }
      setLoading(false);
    });

    const refreshInterval = setInterval(async () => {
      const { data: { session: currentSession } } = await supabase.auth.getSession();
      if (currentSession && isTokenExpired(currentSession)) {
        await refreshToken();
      }
    }, 5 * 60 * 1000);

    return () => {
      subscription.unsubscribe();
      clearInterval(refreshInterval);
    };
  }, []);

  const value = {
    session,
    loading,
    refreshToken,
    isTokenExpired,
    permissions,
    tenantType,
    activeTenantId,
    activeTenantName,
    workspaces
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 