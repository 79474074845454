import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { supabase } from '../../../supabaseClient';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import WebhookIcon from '@mui/icons-material/Webhook';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

function MyCustomWorkflows() {
  const { activeTenantId } = useAuth();
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const { data, error } = await supabase
          .from('n8n_workflows')
          .select('*')
          .eq('tenant_id', activeTenantId)
          .eq('is_archived', false)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setWorkflows(data);
      } catch (err) {
        console.error('Error fetching workflows:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (activeTenantId) {
      fetchWorkflows();
    }
  }, [activeTenantId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Alert severity="error">Error: {error}</Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        My Custom Workflows
      </Typography>

      {workflows.length === 0 ? (
        <Alert severity="info">No workflows found</Alert>
      ) : (
        <Grid container spacing={3}>
          {workflows.map((workflow) => (
            <Grid item xs={12} md={6} lg={4} key={workflow.id}>
              <Card 
                elevation={2}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: (theme) => theme.shadows[4]
                  }
                }}
                onClick={() => navigate(`/my-custom-workflows/${workflow.id}`)}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {workflow.meta?.name || `Workflow ${workflow.id}`}
                  </Typography>
                  
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    Created {formatDistanceToNow(new Date(workflow.created_at))} ago
                  </Typography>

                  {workflow.meta?.webhooks && workflow.meta.webhooks.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Webhooks:
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {workflow.meta.webhooks.map((webhook) => (
                          <Chip
                            key={webhook.id}
                            icon={<WebhookIcon />}
                            label={webhook.name}
                            size="small"
                            variant="outlined"
                            color="secondary"
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default MyCustomWorkflows;
