export const PARAMETER_TYPES = [
    { value: 'string', label: 'Text' },
    { value: 'date', label: 'Date' },
    { value: 'integer', label: 'Integer Number' },
    { value: 'float', label: 'Floating Point Number' },
    { value: 'string_select', label: 'Text Select' },
    { value: 'integer_select', label: 'Integer Number Select' },
    { value: 'string_multiselect', label: 'Text Multiselect' },
    { value: 'integer_multiselect', label: 'Integer Number Multiselect' },
    { value: 'boolean', label: 'Boolean' },
    { value: 'array', label: 'Free Array' },
]; 