import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Tooltip,
    Chip,
    TablePagination
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../context/AuthContext';
import Link from '@mui/material/Link';

const BatchDetails = () => {
    const { id, batchId } = useParams();
    const { activeTenantId } = useAuth();
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [columns, setColumns] = useState([]);
    const [originalFields, setOriginalFields] = useState(new Set());

    const getColumns = (records) => {
        if (!records || records.length === 0) return [];

        // Collect all possible fields from all records
        const allFields = new Set();
        const originalFieldsSet = new Set();

        records.forEach(record => {
            const originalData = record.original_data || {};
            const finalResult = record.final_result || {};

            // Add original fields to both sets
            Object.keys(originalData).forEach(field => {
                allFields.add(field);
                originalFieldsSet.add(field);
            });

            // Add final result fields to allFields only
            Object.keys(finalResult).forEach(field => {
                allFields.add(field);
            });
        });

        // Remove special fields and content removed fields
        const filteredFields = Array.from(allFields).filter(field => {
            if (field === '_cleaned_fields') return false;
            
            // Check all records for this field
            return !records.some(record => {
                const value = record.final_result?.[field] || record.original_data?.[field];
                return typeof value === 'string' && value.includes('<content removed>');
            });
        });

        // Store original fields for styling
        setOriginalFields(originalFieldsSet);

        return filteredFields;
    };

    const fetchRecords = async () => {
        try {
            setLoading(true);
            console.log('Fetching records with params:', {
                batchId,
                activeTenantId,
                page,
                rowsPerPage,
                range: {
                    from: page * rowsPerPage,
                    to: (page + 1) * rowsPerPage - 1
                }
            });

            const { data, error, count } = await supabase
                .from('record_processings')
                .select('*', { count: 'exact' })
                .eq('batch_id', batchId)
                .eq('tenant_id', activeTenantId)
                .range(page * rowsPerPage, (page + 1) * rowsPerPage - 1)
                .order('created_at', { ascending: false })
                .order('row_index', { ascending: true });

            if (error) {
                console.error('Query error:', error);
                throw error;
            }

            console.log('Query results:', {
                data,
                count,
                error
            });
            
            if (data && data.length > 0) {
                const columnsList = getColumns(data); // Pass all records instead of just the first one
                setColumns(columnsList);
            }
            
            setRecords(data || []);
        } catch (error) {
            console.error('Error fetching records:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecords();
    }, [batchId, page, rowsPerPage]);

    const getStatusLabel = (status) => {
        switch (status) {
            case 'PENDING':
                return 'Waiting';
            case 'RUNNING':
                return 'Running';
            case 'COMPLETED':
                return 'Done';
            case 'FAILED':
                return 'Error';
            case 'COMPLETED_STOPPED':
                return 'Filtered Out';
            default:
                return status;
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'COMPLETED':
                return 'success';
            case 'RUNNING':
                return 'primary';
            case 'FAILED':
                return 'error';
            case 'COMPLETED_STOPPED':
                return 'warning';
            default:
                return 'default';
        }
    };

    const formatCellContent = (value) => {
        if (!value) return '';

        // Convert to string
        const stringValue = String(value);

        // Check if it's a URL
        const isUrl = /^(http|https):\/\/[^ "]+$/.test(stringValue);
        
        if (isUrl) {
            return (
                <Link href={stringValue} target="_blank" rel="noopener noreferrer">
                    link
                </Link>
            );
        }

        // If text is longer than 50 characters, trim it and add tooltip
        if (stringValue.length > 50) {
            return (
                <Tooltip title={stringValue}>
                    <Typography
                        sx={{
                            maxHeight: '2.4em', // Approximately 2 lines of text
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {stringValue.substring(0, 50) + '...'}
                    </Typography>
                </Tooltip>
            );
        }

        return stringValue;
    };

    const getCellValue = (record, column) => {
        const value = record.final_result?.[column] ?? record.original_data?.[column];
        if (typeof value === 'boolean') {
            return value ? 'Yes' : 'No';
        }
        // Only format final result values, not original data
        if (record.final_result?.[column] !== undefined) {
            return formatCellContent(value);
        }
        return value;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
                Batch Processing Details
            </Typography>

            <Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                    Status
                                </TableCell>
                                {columns.map((column) => (
                                    <TableCell 
                                        key={column}
                                        sx={{
                                            fontWeight: 'bold',
                                            backgroundColor: originalFields.has(column) ? 'action.hover' : 'inherit',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length + 1} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : records.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length + 1} align="center">
                                        No records found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                records.map((record) => (
                                    <TableRow key={record.id}>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Chip
                                                    label={getStatusLabel(record.status)}
                                                    color={getStatusColor(record.status)}
                                                    size="small"
                                                />
                                                {record.error_message && (
                                                    <Tooltip title={record.error_message}>
                                                        <ErrorOutlineIcon 
                                                            color="error" 
                                                            sx={{ fontSize: 20 }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </TableCell>
                                        {columns.map((column) => (
                                            <TableCell 
                                                key={column}
                                                sx={{
                                                    maxWidth: '200px',
                                                    height: '2.4em',
                                                    lineHeight: '1.2em',
                                                    padding: '8px'
                                                }}
                                            >
                                                {getCellValue(record, column)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    count={-1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default BatchDetails; 