import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Chip,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import { supabase } from '../../supabaseClient';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from '../../context/SnackbarContext';
import PageHeader from '../../context/PageHeader';
import { useAuth } from '../../context/AuthContext';

const WorkspaceSettings = () => {
    const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
    const ENV_HUBSPOT_CLIENT_ID = window.env?.REACT_APP_HUBSPOT_CLIENT_ID || process.env.REACT_APP_HUBSPOT_CLIENT_ID;
    const ENV_HUBSPOT_REGION = window.env?.REACT_APP_HUBSPOT_REGION || process.env.REACT_APP_HUBSPOT_REGION;
    const [workspace, setWorkspace] = useState(null);
    const [workspaceName, setWorkspaceName] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const processedCode = useRef(null);
    const location = useLocation();
    const [workspaceUsers, setWorkspaceUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [inviting, setInviting] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [pendingInvites, setPendingInvites] = useState([]);
    const [selectedRole, setSelectedRole] = useState('user');

    // Get all necessary data from AuthContext
    const { 
        session, 
        activeTenantId,
        activeTenantName,  
        workspaces,     
        refreshToken,
        tenantType
    } = useAuth();


    const getRoleOptions = (tenantType) => {
        switch (tenantType) {
            case 'app_admin':
                return [
                    { value: 'App Super Admin', label: 'App Super Admin' },
                    { value: 'App Support', label: 'App Support' }
                ];
            case 'mate_owner':
                return [
                    { value: 'Mate Owner Admin', label: 'Mate Owner Admin' },
                    { value: 'Mate Owner Support', label: 'Mate Owner Support' }
                ];
            case 'end_user':
                return [
                    { value: 'Workspace Admin', label: 'Workspace Admin' },
                    { value: 'Workspace Editor', label: 'Workspace Editor' },
                    { value: 'Workspace User', label: 'Workspace User' }
                ];
            default:
                return [];
        }
    };

    const ROLE_OPTIONS = getRoleOptions(tenantType);

    // HubSpot OAuth configuration
    const HUBSPOT_CLIENT_ID = ENV_HUBSPOT_CLIENT_ID;
    const REDIRECT_URI = `${window.location.origin}/workspace/`;
    const HUBSPOT_REGION = ENV_HUBSPOT_REGION;
    const SCOPES = [
        'crm.lists.read',
        'crm.objects.companies.read',
        'crm.objects.companies.write',
        'crm.objects.contacts.read',
        'crm.objects.contacts.write',
        'crm.objects.deals.read',
        'crm.objects.deals.write',
        'crm.schemas.companies.read',
        'crm.schemas.companies.write',
        'crm.schemas.contacts.read',
        'crm.schemas.contacts.write',
        'crm.schemas.deals.read',
        'crm.schemas.deals.write',
        'oauth'
    ].join(' ');

    const { showSnackbar } = useSnackbar();



    useEffect(() => {
        if (activeTenantId) {
            fetchWorkspaceData();
            fetchWorkspaceUsers();
        }
    }, [activeTenantId]);

    useEffect(() => {
        const handleOAuthCallback = async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');

            if (code && !isProcessing && processedCode.current !== code) {
                // Wait for workspaceId to be available
                if (!activeTenantId) {
                    console.log('Waiting for workspace ID...');
                    return;
                }

                setIsProcessing(true);
                processedCode.current = code;

                try {
                    const hubspotData = await exchangeCodeForToken(code);
                    await updateHubSpotIntegration(hubspotData);

                    // Clear the code from the URL
                    window.history.replaceState({}, document.title, window.location.pathname);

                    showSnackbar('HubSpot connected successfully');
                } catch (error) {
                    console.error('Error handling HubSpot callback:', error);
                    if (!error.message.includes('Code already used')) {
                        setError('Failed to connect HubSpot: ' + error.message);
                        showSnackbar('Failed to connect HubSpot', 'error');
                    }
                } finally {
                    setIsProcessing(false);
                }
            }
        };

        if (activeTenantId) {  // Only run callback if workspaceId is available
            handleOAuthCallback();
        }

        return () => {
            setIsProcessing(false);
        };
    }, [location.search, activeTenantId]); // Add workspaceId as dependency

    // Handle the OAuth callback from HubSpot
    const handleHubSpotCallback = async () => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');

        if (code) {
            try {
                const hubspotData = await exchangeCodeForToken(code);

                await updateHubSpotIntegration(hubspotData);

                // Clear the code from the URL
                window.history.replaceState({}, document.title, window.location.pathname);

                showSnackbar('HubSpot connected successfully');
            } catch (error) {
                console.error('Error handling HubSpot callback:', error);
                setError('Failed to connect HubSpot: ' + error.message);
                setTimeout(() => setError(null), 3000);
            }
        }
    };

    // Initiate HubSpot OAuth flow
    const connectHubSpot = () => {
        const authUrl = `https://app-${HUBSPOT_REGION}.hubspot.com/oauth/authorize?` +
            `client_id=${HUBSPOT_CLIENT_ID}&` +
            `redirect_uri=${encodeURIComponent(REDIRECT_URI)}&` +
            `scope=${encodeURIComponent(SCOPES)}`;

        window.location.href = authUrl;
    };

    // Update workspace with HubSpot integration data
    const updateHubSpotIntegration = async (hubspotData) => {
        if (!activeTenantId) {
            throw new Error('No workspace selected');
        }

        try {
            const { data: workspaceData, error: fetchError } = await supabase
                .from('tenants')
                .select('*')
                .eq('id', activeTenantId)
                .single();

            if (fetchError) throw fetchError;

            const currentMeta = workspaceData.meta || {};
            const expiresOn = new Date(Date.now() + (hubspotData.expires_in * 1000)).toISOString();

            const updatedMeta = {
                ...currentMeta,
                integrations: [
                    ...(currentMeta.integrations || []).filter(
                        integration => !integration.HubSpot
                    ),
                    {
                        HubSpot: {
                            access_token: hubspotData.access_token,
                            refresh_token: hubspotData.refresh_token,
                            expires_on: expiresOn,
                            token_type: hubspotData.token_type
                        }
                    }
                ]
            };

            const { error: updateError } = await supabase
                .from('tenants')
                .update({ meta: updatedMeta })
                .eq('id', activeTenantId);

            if (updateError) throw updateError;

            setWorkspace({ ...workspaceData, meta: updatedMeta });

        } catch (error) {
            console.error('Error updating HubSpot integration:', error);
            throw error;
        }
    };

    // Fetch workspace data
    const fetchWorkspaceData = async () => {
        if (!activeTenantId) return;

        try {
            const { data, error } = await supabase
                .from('tenants')
                .select('*')
                .eq('id', activeTenantId)
                .single();

            if (error) throw error;

            setWorkspace(data);
            setWorkspaceName(data.name || '');
        } catch (error) {
            console.error('Error fetching workspace:', error);
            setError('Failed to fetch workspace data');
        }
    };

    // Handle workspace name update
    const handleUpdateName = async () => {
        try {
            console.log("Updating workspace name:", workspaceName, "for tenant:", activeTenantId);
            const { data, error } = await supabase
                .from('tenants')
                .update({ name: workspaceName })
                .eq('id', activeTenantId)
                .select();

            console.log("Updated workspace data:", data);

            if (error) throw error;

            // Fetch updated workspace data to refresh the UI
            await fetchWorkspaceData();
            // Dispatch custom event for workspace update
            window.dispatchEvent(new CustomEvent('workspaceUpdate', {
                detail: {
                    tenantId: activeTenantId,
                    newName: workspaceName
                }
            }));

            setIsEditing(false);
            showSnackbar('Workspace name updated successfully');
        } catch (error) {
            console.error('Error updating workspace name:', error);
            setError('Failed to update workspace name: ' + error.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    // Add this function to your component
    const exchangeCodeForToken = async (code) => {
        try {
            const response = await fetch(`${API_URL}/api/hubspot/oauth/callback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to exchange code for token');
            }

            return data;
        } catch (error) {
            console.error('Error exchanging code for token:', error);
            throw error;
        }
    };

    const refreshHubSpotToken = async (refreshToken) => {
        try {
            const response = await fetch(`${API_URL}/api/hubspot/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh_token: refreshToken })
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token');
            }

            return await response.json();
        } catch (error) {
            console.error('Error refreshing token:', error);
            throw error;
        }
    };

    const getValidHubSpotToken = async () => {
        try {
            const { data: tenant, error } = await supabase
                .from('tenants')
                .select('meta')
                .eq('id', activeTenantId)
                .single();

            if (error) throw error;

            const hubspotIntegration = tenant.meta?.integrations?.find(i => i.HubSpot);
            if (!hubspotIntegration) {
                throw new Error('No HubSpot integration found');
            }

            const { HubSpot: hubspot } = hubspotIntegration;
            const expiresOn = new Date(hubspot.expires_on);
            const now = new Date();

            // If token expires in less than 10 seconds, refresh it
            if ((expiresOn - now) / 1000 < 10) {
                const newTokenData = await refreshHubSpotToken(hubspot.refresh_token);
                await updateHubSpotIntegration(newTokenData);
                return newTokenData.access_token;
            }

            return hubspot.access_token;
        } catch (error) {
            console.error('Error getting valid token:', error);
            throw error;
        }
    };

    // Updated function to fetch workspace users
    const fetchWorkspaceUsers = async () => {
        if (!activeTenantId) return;
        
        setLoadingUsers(true);
        try {
            const { data: workspaceUsers, error } = await supabase
                .rpc('get_workspace_users', {
                    workspace_id: activeTenantId
                });

            console.log("Workspace users:", workspaceUsers);

            if (error) throw error;

            const users = workspaceUsers.map(user => ({
                id: user.user_id,
                email: user.email,
                name: user.name,
                role: user.role,
                status: user.status,
                created_at: user.created_at,
                accepted_at: user.accepted_at,
                invited_by_email: user.invited_by_email
            }));

            setWorkspaceUsers(users);
        } catch (error) {
            console.error('Error fetching workspace users:', error);
            setError('Failed to fetch workspace users');
        } finally {
            setLoadingUsers(false);
        }
    };

    // Email validation function
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Handle invite modal
    const handleOpenInviteModal = () => setOpenInviteModal(true);
    const handleCloseInviteModal = () => {
        setOpenInviteModal(false);
        setInviteEmail('');
        setEmailError('');
    };

    // Handle email input change
    const handleEmailChange = (e) => {
        const email = e.target.value;
        setInviteEmail(email);
        if (email && !validateEmail(email)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    // Handle invite submission
    const handleInviteUser = async () => {
        if (!validateEmail(inviteEmail)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        setInviting(true);
        try {
            const { data: { session } } = await supabase.auth.getSession();
            
            const response = await fetch(`${API_URL}/api/workspace/users/invite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    email: inviteEmail,
                    inviter_id: session.user.id,
                    workspace_id: activeTenantId,
                    role: selectedRole
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to send invitation');
            }

            showSnackbar('Invitation sent successfully');
            handleCloseInviteModal();
            await fetchWorkspaceUsers();
            
        } catch (error) {
            console.error('Error inviting user:', error);
            showSnackbar(error.message, 'error');
        } finally {
            setInviting(false);
        }
    };

    useEffect(() => {
        const checkUserRole = async () => {
            if (!session?.user?.id || !activeTenantId) return;

            try {
                const { data, error } = await supabase
                    .from('user_roles')
                    .select('role')
                    .eq('user_id', session.user.id)
                    .eq('tenant_id', activeTenantId)
                    .single();

                if (error) throw error;
                setUserRole(data.role);
            } catch (error) {
                console.error('Error checking user role:', error);
            }
        };

        checkUserRole();
    }, [session, activeTenantId]);


    // Add a helper function for date formatting
    const formatDate = (dateString) => {
        if (!dateString) return '---';
        try {
            return new Date(dateString).toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        } catch (error) {
            console.error('Error formatting date:', dateString, error);
            return '---';
        }
    };

    // Add role mapping helper
    const getRoleLabel = (roleValue) => {
        const role = ROLE_OPTIONS.find(option => option.value === roleValue);
        return role ? role.label : roleValue;
    };

    const canInviteUsers = (role) => {
        return role === 'Workspace Admin' || 
               role === 'App Super Admin' || 
               role === 'Mate Owner Admin';
    };

    return (
        <Box sx={{ p: 0 }}>
            <Paper sx={{ p: 3 }}>
                {/* Workspace Name Section */}
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Workspace Name
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {isEditing ? (
                            <>
                                <TextField
                                    value={workspaceName}
                                    onChange={(e) => setWorkspaceName(e.target.value)}
                                    size="small"
                                    fullWidth
                                />
                                <Button
                                    variant="contained"
                                    onClick={handleUpdateName}
                                    size="small"
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={() => setIsEditing(false)}
                                    size="small"
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography>{workspaceName}</Typography>
                                <Button
                                    variant="outlined"
                                    onClick={() => setIsEditing(true)}
                                    size="small"
                                >
                                    Edit
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>

                {/* HubSpot Integration Section */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        HubSpot Integration
                    </Typography>
                    {workspace?.meta?.integrations?.find(i => i.HubSpot) ? (
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                Connected to HubSpot
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={connectHubSpot}
                                size="small"
                            >
                                Reconnect HubSpot
                            </Button>
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={connectHubSpot}
                            size="small"
                        >
                            Connect HubSpot
                        </Button>
                    )}
                </Box>

            </Paper>
            <Paper sx={{ p: 3, paddingTop: 2, marginTop: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">Users</Typography>
                    <Button
                        variant="contained"
                        onClick={handleOpenInviteModal}
                        size="small"
                        disabled={!canInviteUsers(userRole)}
                    >
                        Invite New User
                    </Button>
                </Box>

                <TableContainer component={Paper} sx={{ mt: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created / Invited on</TableCell>
                                <TableCell>Accepted on</TableCell>
                                <TableCell>Invited by</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loadingUsers ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <CircularProgress size={24} />
                                    </TableCell>
                                </TableRow>
                            ) : workspaceUsers.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No users found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                workspaceUsers.map((user) => (
                                    <TableRow key={user.email}>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.name || '---'}</TableCell>
                                        <TableCell>
                                            {getRoleLabel(user.role)}
                                        </TableCell>
                                        <TableCell>
                                            <Chip 
                                                label={user.status}
                                                color={
                                                    user.status === 'Owner' ? 'success' :
                                                    user.status === 'Active' ? 'primary' :
                                                    'warning'
                                                }
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {formatDate(user.created_at)}
                                        </TableCell>
                                        <TableCell>
                                            {formatDate(user.accepted_at)}
                                        </TableCell>
                                        <TableCell>
                                            {user.invited_by_email || '---'}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* Invite User Modal */}
            <Dialog
                open={openInviteModal}
                onClose={handleCloseInviteModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Invite New User</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                        <TextField
                            autoFocus
                            label="Email Address"
                            type="email"
                            fullWidth
                            value={inviteEmail}
                            onChange={handleEmailChange}
                            error={!!emailError}
                            helperText={emailError}
                            disabled={inviting}
                        />

                        <FormControl fullWidth size="small">
                            <InputLabel>Role</InputLabel>
                            <Select
                                value={selectedRole}
                                onChange={(e) => setSelectedRole(e.target.value)}
                                label="Role"
                            >
                                {ROLE_OPTIONS.map((role) => (
                                    <MenuItem key={role.value} value={role.value}>
                                        {role.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Typography variant="caption" color="text.secondary">
                            {selectedRole === 'super_admin' &&
                                'Super Admin can manage workspace settings and users'
                            }
                            {selectedRole === 'editor' &&
                                'Power User can access all features but cannot manage workspace settings'
                            }
                            {selectedRole === 'user' &&
                                'User has basic access to workspace features'
                            }
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseInviteModal}
                        disabled={inviting}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleInviteUser}
                        variant="contained"
                        disabled={!inviteEmail || !!emailError || inviting}
                    >
                        {inviting ? <CircularProgress size={24} /> : 'Invite'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default WorkspaceSettings;
