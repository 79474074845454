import palette from './palette';

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        minHeight: '100vh',
        overflow: 'auto',
        color: 'var(--color-text)',
        background: 'var(--color-background)',
        transition: 'color 0.5s, background-color 0.5s',
        textRendering: 'optimizeLegibility',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale'
      }
    }
  },
  MuiLoginWrapper: {
    styleOverrides: {
      root: {
        display: 'flex',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden'
      },
      left: {
        flex: 1,
        maxWidth: '40%',
        backgroundColor: 'var(--color-background)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
        textAlign: 'center',
        '& img': {
          maxWidth: 200,
          marginBottom: '3rem'
        },
        '& form': {
          width: '100%',
          maxWidth: '320px'
        }
      },
      right: {
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        }
      }
    }
  },
  MuiLayout: {
    styleOverrides: {
      root: {
        display: 'flex',
        minHeight: '100vh',
        position: 'relative'
      },
      sidebar: {
        width: 280,
        position: 'fixed',
        height: '100vh',
        backgroundColor: 'var(--color-background)',
        borderRight: `1px solid ${palette.divider}`,
        transition: 'width 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1200,
        '&.collapsed': {
          width: 70
        }
      },
      content: {
        flexGrow: 1,
        marginLeft: 280,
        padding: 20,
        transition: 'margin-left 0.3s ease',
        position: 'relative',
        zIndex: 1,
        '&.collapsed': {
          marginLeft: 70
        }
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: 8,
      },
      contained: {
        '&:hover': {
          backgroundColor: palette.primary.dark,
        }
      },
      outlined: {
        borderColor: palette.divider,
        '&:hover': {
          backgroundColor: palette.action.hover,
          borderColor: palette.primary.main
        }
      },
      text: {
        '&:hover': {
          backgroundColor: palette.action.hover
        }
      }
    },
    defaultProps: {
      disableElevation: true
    }
  }
};

export default components; 