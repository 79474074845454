import { useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import Layout from './components/layout';
import Login from './components/auth/login';
import ForgotPassword from './components/auth/forgot-password';
import theme from './theme/theme';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { SnackbarProvider } from './context/SnackbarContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';
import CustomSignup from './components/auth/signup';

function AppContent() {
  const { session, loading } = useAuth();

  if (loading) {
    return (
      <div className="loading-container">
        <span className="loading-text">Loading...</span>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/login" element={!session ? <Login /> : <Navigate to="/" />} />
      <Route path="/signup" element={!session ? <CustomSignup /> : <Navigate to="/" />} />
      <Route 
        path="/*" 
        element={session ? <Layout session={session} /> : <Navigate to="/login" />} 
      />
    </Routes>
  );
}

export default function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <AuthProvider>
            <CssBaseline />
            <AppContent />
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}
