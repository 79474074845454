import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    Stack,
    Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useNavigate } from 'react-router-dom';

const WorkflowsTable = ({ workflows, onEdit }) => {
    const navigate = useNavigate();

    const handleConfigure = (workflow) => {
        navigate(`/enrichment/workflows/${workflow.canonical_id}/configure`);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Version</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workflows.map((workflow) => (
                        <TableRow key={workflow.id}>
                            <TableCell>{workflow.name}</TableCell>
                            <TableCell>{workflow.description}</TableCell>
                            <TableCell>v{workflow.version}</TableCell>
                            <TableCell align="right">
                                <Stack direction="row" spacing={1} justifyContent="flex-end">
                                    <Tooltip title="Edit">
                                        <IconButton 
                                            size="small"
                                            onClick={() => onEdit(workflow)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Configure Actions">
                                        <IconButton 
                                            size="small"
                                            onClick={() => handleConfigure(workflow)}
                                        >
                                            <SettingsIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Run Workflow">
                                        <IconButton 
                                            size="small"
                                            onClick={() => navigate(`/enrichment/workflows/${workflow.canonical_id}/run`)}
                                            color="primary"
                                        >
                                            <PlayArrowIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default WorkflowsTable; 