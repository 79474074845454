import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    Stack
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../supabaseClient';
import WorkflowsTable from './WorkflowsTable';
import { v4 as uuidv4 } from 'uuid';

const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

const DataEnrichment = () => {
    const { activeTenantId, session } = useAuth();
    const [workflows, setWorkflows] = useState([]);
    const [icpSegments, setIcpSegments] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    useEffect(() => {
        fetchWorkflows();
        fetchIcpSegments();
    }, [activeTenantId]);

    const fetchWorkflows = async () => {
        try {
            const response = await fetch(`${API_URL}/api/pipelines/listwoicp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    tenant_id: activeTenantId
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch workflows');
            }

           

            const data = await response.json();
            console.log(data);
            setWorkflows(data || []);
        } catch (error) {
            console.error('Error fetching workflows:', error);
        }
    };

    const fetchIcpSegments = async () => {
        try {
            const { data, error } = await supabase
                .from('icp_segments')
                .select('id, icp_name')
                .eq('tenant_id', activeTenantId);

            if (error) throw error;
            setIcpSegments(data || []);
        } catch (error) {
            console.error('Error fetching ICP segments:', error);
        }
    };

    const handleOpenDialog = (workflow = null) => {
        if (workflow) {
            setSelectedWorkflow(workflow);
            setFormData({
                name: workflow.name || '',
                description: workflow.description || ''
            });
        } else {
            setSelectedWorkflow(null);
            setFormData({
                name: '',
                description: ''
            });
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedWorkflow(null);
        setFormData({
            name: '',
            description: ''
        });
    };

    const handleSubmit = async () => {
        try {
            if (selectedWorkflow) {
                const workflowWithActions = await supabase
                    .from('icp_segment_enrichment_workflows')
                    .select('*')
                    .eq('id', selectedWorkflow.id)
                    .eq('tenant_id', activeTenantId)
                    .single();

                const { error } = await supabase
                    .from('icp_segment_enrichment_workflows')
                    .insert([{
                        ...formData,
                        tenant_id: activeTenantId,
                        canonical_id: selectedWorkflow.canonical_id,
                        version: (selectedWorkflow.version || 0) + 1,
                        actions: workflowWithActions.data.actions
                    }]);

                if (error) throw error;
            } else {
                const { error } = await supabase
                    .from('icp_segment_enrichment_workflows')
                    .insert([{
                        ...formData,
                        tenant_id: activeTenantId,
                        canonical_id: uuidv4(),
                        version: 1
                    }]);

                if (error) throw error;
            }

            handleCloseDialog();
            fetchWorkflows();
        } catch (error) {
            console.error('Error saving workflow:', error);
        }
    };

    

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography variant="h5">Data Enrichment Workflows</Typography>
                <Button 
                    variant="contained" 
                    onClick={() => handleOpenDialog()}
                >
                    New Workflow
                </Button>
            </Box>

            <WorkflowsTable 
                workflows={workflows}
                onEdit={handleOpenDialog}
            />


            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {selectedWorkflow ? 'Edit Workflow' : 'New Workflow'}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={3} sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            value={formData.description}
                            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                            multiline
                            rows={4}
                        />
                        <TextField
                            select
                            fullWidth
                            label="ICP Segment"
                            hidden
                            value={formData.icp_segment_id}
                            onChange={(e) => setFormData(prev => ({ ...prev, icp_segment_id: e.target.value }))}
                        >
                            {icpSegments.map((segment) => (
                                <MenuItem key={segment.id} value={segment.id}>
                                    {segment.icp_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSubmit} variant="contained">
                        {selectedWorkflow ? 'Save' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DataEnrichment; 