import React, { useState, useEffect, useRef } from 'react';
import { 
    Box, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    TextField,
    Button,
    IconButton,
    Typography,
    Tabs,
    Tab,
    Paper,
    Autocomplete,
    Popover,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { PARAMETER_TYPES } from '../../../../constants/parameterTypes';

// Custom TabPanel component
function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const HighlightedTextField = ({ value, onChange, availableFields = [], ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const textFieldRef = useRef(null);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        onChange({ target: { value: newValue } });

        const cursorPosition = e.target.selectionStart;
        const lastHashIndex = newValue.lastIndexOf('#', cursorPosition - 1);

        if (lastHashIndex !== -1 && cursorPosition > lastHashIndex) {
            const query = newValue.slice(lastHashIndex + 1, cursorPosition);
            const filteredFields = availableFields.filter((field) => {
                const fieldName = typeof field === 'object' ? field.name : field;
                return fieldName.toLowerCase().startsWith(query.toLowerCase());
            });

            if (filteredFields.length > 0) {
                const textField = textFieldRef.current;
                const rect = textField.getBoundingClientRect();
                const caretCoords = getCaretCoordinates(textField, lastHashIndex);
                
                setAnchorEl({
                    top: rect.top + caretCoords.top + 20,
                    left: rect.left + caretCoords.left + 12,
                });
                setSelectedIndex(0);
            } else {
                setAnchorEl(null);
            }
        } else {
            setAnchorEl(null);
        }
    };

    const getCaretCoordinates = (element, position) => {
        const div = document.createElement('div');
        const copyStyle = getComputedStyle(element);
        for (const prop of copyStyle) {
            div.style[prop] = copyStyle[prop];
        }
        div.style.position = 'absolute';
        div.style.visibility = 'hidden';
        div.style.whiteSpace = 'pre-wrap';
        div.textContent = element.value.substring(0, position);
        const span = document.createElement('span');
        span.textContent = element.value.substring(position) || '.';
        div.appendChild(span);
        document.body.appendChild(div);
        const { offsetTop: top, offsetLeft: left } = span;
        document.body.removeChild(div);
        return { top, left };
    };

    const handleFieldSelect = (field) => {
        const fieldName = typeof field === 'object' ? field.name : field;
        const cursorPosition = textFieldRef.current.selectionStart;
        const lastHashIndex = value.lastIndexOf('#', cursorPosition - 1);

        const beforeHash = value.slice(0, lastHashIndex);
        const afterCursor = value.slice(cursorPosition);

        const updatedValue = `${beforeHash}{{{${fieldName}}}}${afterCursor}`;
        onChange({ target: { value: updatedValue } });
        setAnchorEl(null);
    };

    const handleKeyDown = (e) => {
        if (anchorEl) {
            const filteredFields = getFilteredFields();

            if (e.key === 'ArrowDown') {
                setSelectedIndex((prev) => (prev + 1) % filteredFields.length);
                e.preventDefault();
            } else if (e.key === 'ArrowUp') {
                setSelectedIndex((prev) => (prev - 1 + filteredFields.length) % filteredFields.length);
                e.preventDefault();
            } else if (e.key === 'Enter') {
                if (filteredFields.length > 0) {
                    handleFieldSelect(filteredFields[selectedIndex]);
                }
                e.preventDefault();
            } else if (e.key === 'Escape') {
                setAnchorEl(null);
                e.preventDefault();
            }
        }
    };

    const getFilteredFields = () => {
        if (!textFieldRef.current) return [];
        const cursorPosition = textFieldRef.current.selectionStart;
        const lastHashIndex = value.lastIndexOf('#', cursorPosition - 1);
        const query = value.slice(lastHashIndex + 1, cursorPosition);

        return availableFields.filter((field) => {
            const fieldName = typeof field === 'object' ? field.name : field;
            return fieldName.toLowerCase().startsWith(query.toLowerCase());
        });
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <TextField
                {...props}
                inputRef={textFieldRef}
                value={value}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                helperText="Type # to reference available fields"
            />
            <Popover
                open={Boolean(anchorEl)}
                anchorReference="anchorPosition"
                anchorPosition={anchorEl}
                onClose={() => setAnchorEl(null)}
                disableAutoFocus
                disableEnforceFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List sx={{ maxHeight: '200px', overflow: 'auto' }}>
                    {getFilteredFields().map((field, index) => (
                        <ListItem
                            button
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => handleFieldSelect(field)}
                            sx={{
                                minWidth: '150px',
                                maxWidth: '250px',
                                py: 0.5,
                                px: 1.5,
                                bgcolor: index === selectedIndex ? 'action.hover' : 'inherit',
                            }}
                        >
                            <ListItemText 
                                primary={typeof field === 'object' ? field.name : field}
                                primaryTypographyProps={{
                                    sx: { fontSize: '0.875rem' }
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </Box>
    );
};

const ParameterPanel = ({ 
    parameters, 
    onParameterUpdate, 
    onParameterDelete, 
    availableFields,
    onParameterAdd 
}) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [editingName, setEditingName] = useState(null);
    const parameterNames = Object.keys(parameters);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        setEditingName(null);
    };

    const handleRename = (oldName, newName) => {
        if (newName && newName !== oldName && !parameters[newName]) {
            const parameterData = { ...parameters[oldName] };
            
            // Instead of calling update and delete separately, 
            // pass both old and new names to update
            onParameterUpdate(oldName, parameterData, newName);
            setEditingName(null);
            
            // Set the current tab to the new parameter
            const newIndex = Object.keys(parameters).indexOf(oldName);
            setCurrentTab(newIndex);
        } else {
            setEditingName(null);
        }
    };

    const handleParameterUpdate = (paramName, updates) => {
        onParameterUpdate(paramName, {
            ...parameters[paramName],  // Preserve existing parameter state
            ...updates  // Apply updates
        });
    };

    const renderParameterContent = (paramName, parameter) => {
        const showEnum = (parameter.type || '').includes('select');
        const isNumberType = (parameter.type || '').includes('integer');

        return (
            <Box sx={{ p: 2 }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Parameter Type</InputLabel>
                    <Select
                        value={parameter.type || 'string'}
                        label="Parameter Type"
                        onChange={(e) => handleParameterUpdate(paramName, { 
                            type: e.target.value
                        })}
                    >
                        {PARAMETER_TYPES.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <HighlightedTextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Parameter Description"
                    value={parameter.description || ''}
                    onChange={(e) => {
                        handleParameterUpdate(paramName, {
                            description: e.target.value
                        });
                    }}
                    availableFields={availableFields}
                    sx={{ mb: 2 }}
                />

                {showEnum && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Values ({(parameter.enum || []).length})
                        </Typography>
                        {(parameter.enum || []).map((item, index) => (
                            <Box key={`enum-value-${index}`} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Value"
                                    value={item.value || ''}
                                    type={isNumberType ? 'number' : 'text'}
                                    onChange={(e) => {
                                        const newEnum = [...(parameter.enum || [])];
                                        newEnum[index] = {
                                            ...item,  // Preserve existing item data
                                            value: isNumberType ? 
                                                parseInt(e.target.value, 10) : 
                                                e.target.value
                                        };
                                        handleParameterUpdate(paramName, {
                                            enum: newEnum
                                        });
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Description"
                                    value={item.description || ''}
                                    onChange={(e) => {
                                        const newEnum = [...(parameter.enum || [])];
                                        newEnum[index] = {
                                            ...item,
                                            description: e.target.value
                                        };
                                        handleParameterUpdate(paramName, {
                                            enum: newEnum
                                        });
                                    }}
                                />
                                <IconButton 
                                    size="small" 
                                    onClick={() => {
                                        const newEnum = [...parameter.enum];
                                        newEnum.splice(index, 1);
                                        handleParameterUpdate(paramName, {
                                            enum: newEnum
                                        });
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button
                            startIcon={<AddIcon />}
                            onClick={() => {
                                const newEnum = [
                                    ...(parameter.enum || []),
                                    { 
                                        value: isNumberType ? 0 : '', 
                                        description: '' 
                                    }
                                ];
                                handleParameterUpdate(paramName, {
                                    enum: newEnum
                                });
                            }}
                            size="small"
                        >
                            Add Value
                        </Button>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Paper sx={{ p: 2 }}>
            {/* Add Header Section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="subtitle2">
                    Parameters Configuration
                </Typography>
                <Button
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={() => {
                        const paramName = `parameter${Object.keys(parameters).length + 1}`;
                        onParameterAdd(paramName);
                    }}
                >
                    Add Parameter
                </Button>
            </Box>

            {/* Existing Parameter Panel Content */}
            <Paper sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                        value={currentTab} 
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {parameterNames.map((paramName) => (
                            <Tab 
                                key={paramName} 
                                label={paramName}
                                sx={{ 
                                    textTransform: 'none',
                                    minHeight: '48px'
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>
                
                {parameterNames.map((paramName, index) => (
                    <TabPanel key={paramName} value={currentTab} index={index}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
                            {editingName === paramName ? (
                                <TextField
                                    size="small"
                                    autoFocus
                                    defaultValue={paramName}
                                    onBlur={(e) => handleRename(paramName, e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleRename(paramName, e.target.value);
                                        }
                                    }}
                                    sx={{ width: '200px' }}
                                />
                            ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="h6">{paramName}</Typography>
                                    <IconButton 
                                        size="small" 
                                        onClick={() => setEditingName(paramName)}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                            <IconButton 
                                size="small" 
                                onClick={() => onParameterDelete(paramName)}
                                color="error"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                        {renderParameterContent(paramName, parameters[paramName])}
                    </TabPanel>
                ))}
            </Paper>
        </Paper>
    );
};

export default React.memo(ParameterPanel);